import React, {Component} from 'react';
import {connect} from 'react-redux';
import {PropTypes} from 'prop-types';

import './styles.sass';
import MenuHeaderTitle from '../../../components/operate/MenuHeaderTitle';
import ActionConfirm from '../../../components/operate/ActionConfirm';
import {menuActions} from '../../../store/actions/menu';
import {heliostatService} from '../../../store/services/heliostat';


const textActionConfirm = `Going into maintenace mode will turn the the heliostat
                            into stow position and stop it’s power production.`;

export class MenuStateThree extends Component {

    onConfirm = () => {
      const {heliostat} = this.props;
      heliostatService.setMaintenanceMode(heliostat.id).then(() => {
        console.log('Setting maintenance mode for heliostat');
        this.props.dispatch(menuActions.setMenuHeliostat());
      }).catch(() => {
        alert('Error while setting maintenance mode for heliostat');
      });
    };

    onCancel = () => {
      this.props.dispatch(menuActions.setMenuHeliostat());
    };

    render() {
      const {heliostat} = this.props;

      return (
        <React.Fragment>
          <MenuHeaderTitle subtitle={'Selected Object:'} title={`Heliostat ${heliostat.name}`}
            online={heliostat.online} />

          <ActionConfirm onCancel={this.onCancel} onConfirm={this.onConfirm} text={textActionConfirm}/>
        </React.Fragment>
      );
    }
}

MenuStateThree.propTypes = {
  heliostat: PropTypes.object,
  dispatch: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    heliostat: state.heliostat.heliostat
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuStateThree);
