const envMode = process.env.REACT_APP_ENVIRONMENT || 'dev';

const apiUrl = process.env.REACT_APP_API ||
  (envMode === 'dev' ? 'https://0xc0262qvg.execute-api.eu-west-1.amazonaws.com/mvp'
    : envMode === 'test' ? 'http://localhost:4010'
      : null);

const socketUrl = process.env.REACT_APP_SOCKET_URL ||
  (envMode === 'dev' ? 'wss://xs4l3n0lch.execute-api.eu-west-1.amazonaws.com/mvp'
    : null);

const unityUrl = process.env.REACT_APP_UNITY_URL;

const cognito = {
  REGION: 'eu-west-1',
  USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID ||
    (envMode === 'dev' ? 'eu-west-1_wAOFYHwBa'
      : 'null'),
  APP_CLIENT_ID: process.env.REACT_APP_COGNITO_CLIENT_ID ||
    (envMode === 'dev' ? '2ushf23shd32krd98bajkfv28m'
      : null)
};

export default {
  API_URL: apiUrl,
  SOCKET_URL: socketUrl,
  UNITY_URL: unityUrl,
  HEARTBEAT_TIMEOUT: envMode === 'dev' ? 500000:5, // heartbeat timeout in seconds
  IS_DEMO: process.env.REACT_APP_IS_DEMO || false,
  MOCK_DATA: false,
  GOOGLE_MAPS_API_KEY: 'AIzaSyC_JYjxpWvf2pPIcQufK5t-NX5KcOLrHQc',
  COGNITO: cognito,
  // dynamic config - can be changed in runtime
  overrideApiUrl: null,
  overrideSocketUrl: null,
  apiKey: null
};
