import React, {Component} from 'react';
import PropTypes from 'prop-types';

import './styles.sass';
import CustomSpinner from '../CustomSpinner';

export class BigButton extends Component {

  render() {
    const {onClick, text, mode} = this.props;
    return (
      <div className={'button-wrapper'}>
        {mode ? (
          <React.Fragment>
            <div className={'info-text'}>Going into STOP mode </div>
            <div className={'progress-text'}>In Progress...</div>
          </React.Fragment>
        ) : ''}
        <button className={'button button--big'} onClick={onClick}>
          {text ? text : <CustomSpinner className={'spinner'}/>}
        </button>
      </div>
    );
  }
}

BigButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  mode: PropTypes.bool
};

export default BigButton;
