import React from 'react';
import './styles.sass';


function EmptyTileItem() {

  return <div className={'empty-item'} />;

}

export default EmptyTileItem;