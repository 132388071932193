import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';

import './styles.sass';
import CollapseMenu from '../../../containers/operate/menu/CollapseMenu';
import PlantLivePreview from '../../../containers/operate/PlantLivePreview';
import {plantActions} from '../../../store/actions/plant';
import {plantModules} from '../../../store/constants/plant';
import TesLiveView from '../../../components/operate/TesLiveView';
import config from '../../../config';
import {getPlantById} from '../../../helpers/store';
import {overrideUrls, removeUrlsOverride} from '../../../helpers/dynamicConfig';
import CriticalEventBar from '../../../components/operate/CriticalEventBar';
import {eventsActions} from '../../../store/actions/events';
import {menuActions} from '../../../store/actions/menu';
import {hasModuleActive} from '../../../helpers/plant';

export class ProjectPage extends Component {

  initializeVideoFunc = null;

  componentDidMount() {
    const {plant, plants, dispatch} = this.props;
    if (!plant) {
      if (!plants || plants.length === 0) {
        dispatch(plantActions.getList()).then(() => this.selectPlant());
      } else {
        this.selectPlant();
      }
    }
  }

  componentWillUnmount() {
    removeUrlsOverride();
  }

  selectPlant() {
    const {plants, dispatch, match} = this.props;
    const plantId = parseInt(match.params.plantId);
    const plant = getPlantById(plants, plantId);
    if (!plant) {
      console.log('Missing plant in plants list');
      return;
    }
    if (config.IS_DEMO) overrideUrls(plant.api_url, plant.ws_url, plant.api_key);
    dispatch(plantActions.select(plant));
    dispatch(plantActions.getStreams());
  }

  setInitializeVideoFunc(initializeVideoFunc) {
    this.initializeVideoFunc = initializeVideoFunc;
  }

  onCriticalEventBarClick() {
    const {dispatch} = this.props;
    dispatch(eventsActions.setShowOnlyCritical(true));
    dispatch(menuActions.setEventsMenu());
  }

  render() {
    const {plant, menuInfo, criticalEvents} = this.props;
    if (!plant) return <div />;

    let liveVideoProps = {};
    if (menuInfo) liveVideoProps.style = {'display': 'none'};

    let mainComponent;
    if (hasModuleActive({plant, modules: [plantModules.HELIOSTATS, plantModules.WEATHER]})) {
      mainComponent = <PlantLivePreview setInitializeVideoFunc={this.setInitializeVideoFunc.bind(this)} />;
    } else if (hasModuleActive({plant, module: plantModules.TES})) {
      mainComponent = <TesLiveView />;
    } else {
      mainComponent = <div />;
    }

    return (
      <div className={'page project-page'}>
        <CollapseMenu initializeLiveVideo={() => this.initializeVideoFunc && this.initializeVideoFunc()}/>
        {mainComponent}
        {criticalEvents && criticalEvents.length > 0 &&
          <CriticalEventBar events={criticalEvents} onClick={this.onCriticalEventBarClick.bind(this)}/>}
      </div>
    );
  }
}

ProjectPage.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  menuInfo: PropTypes.bool,
  plant: PropTypes.object,
  plants: PropTypes.array,
  criticalEvents: PropTypes.array
};

const mapStateToProps = (state) => {
  return {
    plant: state.plant.plant,
    menuInfo: state.menu.menuInfo,
    plants: state.plant.plants,
    criticalEvents: state.events?.criticalEvents
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProjectPage));

