import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import './styles.sass';
import {menuActions} from '../../../store/actions/menu';
import {eventsService} from '../../../store/services/events';
import AlertEventItem from '../../../components/operate/AlertEventItem';
import MenuEventsTitle from '../../../components/operate/MenuEventsTitle';
import {eventsActions} from '../../../store/actions/events';
import CustomSpinner from '../../../components/operate/CustomSpinner';
import Switch from '../../../components/operate/Switch';

export class MenuEventsInfo extends Component {
  state = {
    intervalCount: 0,
    dismissingAll: false,
    eventSystemOn: this.props.eventSystemOn
  };

  menuDetailsShow = (event) => {
    this.props.dispatch(menuActions.setEventDetailsMenu(event));
  };

  dismissAll = () => {
    const {dispatch} = this.props;
    this.setState({dismissingAll: true});
    eventsService.dismissAll().then(() => {
      dispatch(menuActions.toggleMenuEvents());
      dispatch(eventsActions.clearEvents());
      this.setState({dismissingAll: false});
    }).catch(err => {
      const message = err.response?.data?.data?.message;
      let text;
      if (message) text = 'Not all events can be dismissed: ' + message;
      else text = 'Error occurred while dismissing events: ' + err;
      alert(text);
      this.setState({dismissingAll: false});
    });
  };

  switchShowingOnlyCritical = () => {
    const {showOnlyCritical, dispatch} = this.props;
    dispatch(eventsActions.setShowOnlyCritical(!showOnlyCritical));
  };

  eventSystemToggle = () => {
    const {dispatch} = this.props;
    const {eventSystemOn} = this.state;
    dispatch(eventsActions.setEventSystem(!eventSystemOn));
    this.setState({eventSystemOn: !eventSystemOn});
  };

  render() {
    const {events, criticalEvents, loadingEvents, loadingEventsError, isReadonly, showOnlyCritical} = this.props;
    const {dismissingAll, eventSystemOn} = this.state;
    const eventList = showOnlyCritical ? criticalEvents:events;
    return (
      <React.Fragment>
        <MenuEventsTitle title={'Recent Events:'} buttonTitle={!isReadonly ? 'dismiss all':null}
          onClick={this.dismissAll.bind(this)} loading={dismissingAll}/>
        <MenuEventsTitle title={'Show:'} buttonTitle={showOnlyCritical ? 'all':'critical'}
          onClick={this.switchShowingOnlyCritical.bind(this)} />
        <div className="items-wrapper">
          {eventList && eventList.map(event => {
            return <AlertEventItem key={event.id} event={event} onClick={() => this.menuDetailsShow(event)}/>;
          })}
          {loadingEvents &&
            <div className='loader'>
              <CustomSpinner size={20} />
              <span>Loading events...</span>
            </div>
          }
          {!loadingEvents && !loadingEventsError && (!eventList || eventList.length === 0) &&
            <div style={{color: 'white'}}>All {showOnlyCritical ? 'critical ':''}events have been resolved</div>
          }
          {!loadingEvents && loadingEventsError &&
            <div className={'error'}>
              Problem occurred while loading events: {loadingEventsError.message}.<br/>
              New events will be visible in this list.
            </div>
          }
        </div>
        {!isReadonly &&
        <div className={clsx('clearfix')}>
          <div className={clsx('event-system-switch-text', 'float-left', eventSystemOn && 'event-system-switch-text--on')}>
            Event System
          </div>
          <div className={clsx('float-right', 'event-system-switch', eventSystemOn && 'event-system__switch--on')}>
            <Switch
              height={28}
              width={60}
              onChange={this.eventSystemToggle.bind(this)}
              checked={eventSystemOn}
            />
          </div>
        </div>
        }
      </React.Fragment>
    );
  }
}

MenuEventsInfo.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      title: PropTypes.string,
      timestamp: PropTypes.number
    })
  ),
  criticalEvents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      title: PropTypes.string,
      timestamp: PropTypes.number
    })
  ),
  loadingEvents: PropTypes.bool.isRequired,
  loadingEventsError: PropTypes.object,
  showOnlyCritical: PropTypes.bool,
  dispatch: PropTypes.func,
  isReadonly: PropTypes.bool,
  eventSystemOn: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    events: state.events.events,
    criticalEvents: state.events.criticalEvents,
    loadingEvents: state.events.loadingEvents,
    loadingEventsError: state.events.loadingEventsError,
    showOnlyCritical: state.events.showOnlyCritical,
    isReadonly: state.general.isReadonly,
    eventSystemOn: state.plant.plant.eventSystemOn
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuEventsInfo);
