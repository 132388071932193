import Amplify from 'aws-amplify';
import config from './config';


export const configureAmplify = () => {
  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: config.COGNITO.REGION,
      userPoolId: config.COGNITO.USER_POOL_ID,
      userPoolWebClientId: config.COGNITO.APP_CLIENT_ID
    },
  });
};
