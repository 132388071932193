import axios from 'axios';
import config from './config';
import {store} from './store';
import {getCurrentAuthenticatedUser} from './helpers/store';

export const configureAxios = () => {
  axios.defaults.baseURL = config.API_URL;
  axios.defaults.headers.common['Content-Type'] = 'application/json';

  // interceptor is needed only in demo mode
  axios.interceptors.request.use(async function (requestConfig) {
    let headers = requestConfig.headers || {};
    if (config.IS_DEMO && config.overrideApiUrl) {
      requestConfig.baseURL = config.overrideApiUrl;
      headers['Authorization'] = `ApiKey ${config.apiKey}`;
    } else {
      const user = await getCurrentAuthenticatedUser(store.dispatch);
      headers['Authorization'] = `AccessToken ${user.accessToken}`;
    }

    requestConfig.headers = headers;
    console.log('New config', requestConfig);
    return requestConfig;
  }, function (error) {
    return Promise.reject(error);
  });
};
