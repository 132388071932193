import axios from 'axios';
import {plantUrls} from '../constants/plant';
import {getPlantId} from '../../helpers/store';


export const plantService = {
  getList,
  getStreams,
  getStatus,
  resumeProduction,
  haltProduction,
  getStreamUrl
};

function getList() {
  return axios.request({
    method: 'GET',
    url: plantUrls.GET_LIST
  });
}

function getStreams() {
  return axios.request({
    method: 'GET',
    url: plantUrls.GET_STREAMS.replace('<plantId>',getPlantId())
  });
}

function getStatus() {
  return axios.request({
    method: 'GET',
    url: `${plantUrls.GET_STATUS}?projectId=${getPlantId()}`
  });
}

function getStreamUrl(streamName) {
  const url = `${plantUrls.GET_STREAM_URL.replace('<plantId>', getPlantId())}?stream_name=${streamName}`;
  return axios.request({
    method: 'GET',
    url: url
  });
}

function resumeProduction() {
  return axios.request({
    method: 'POST',
    url: `${plantUrls.RESUME_PRODUCTION}?projectId=${getPlantId()}`
  });
}

function haltProduction() {
  return axios.request({
    method: 'POST',
    url: `${plantUrls.HALT_PRODUCTION}?projectId=${getPlantId()}`
  });
}
