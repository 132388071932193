import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {Auth} from 'aws-amplify';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import './styles.sass';
import routes from '../../routes';
import {logo} from '../../components/logo';
import PasswordChangeForm from '../../components/PasswordChange';
import {Button} from 'react-bootstrap';
import {usersActions} from '../../store/actions/users';
import AvailableFiles from '../../containers/AvailableFiles';

export class ProfilePage extends Component {

  state = {
    loading: false,
    showForm: false,
    password: '',
    newPassword1: '',
    newPassword2: '',
  }

  constructor(props) {
    super(props);
    this.onInputChange = this.onInputChange.bind(this);
    this.onChangePasswordSubmit = this.onChangePasswordSubmit.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  onInputChange = (event) => {
    const { target } = event;
    this.setState({[target.name]: target.value});
  }

  async onChangePasswordSubmit() {
    const {password, newPassword1, newPassword2} = this.state;
    if (!password || password === '' || !newPassword1 || newPassword1 === '' || !newPassword2 ||
      newPassword2 === '' || newPassword1 !== newPassword2) {
      alert('Please provide valid passwords. Password cannot be empty. Passwords must be identical.');
      return;
    }
    this.setState({ loading: true });
    try {
      const user = await Auth.currentAuthenticatedUser();
      const response = await Auth.changePassword(user, password, newPassword1);
      this.setState({ loading: false });
      if (response === 'SUCCESS') {
        this.setState({password: '', newPassword1: '', newPassword2: '', showForm: false});
      } else {
        alert('Cannot set new password');
      }
    } catch (e) {
      alert('Cannot set new password: ' + e.message);
      console.log(e);
      this.setState({ loading: false });
    }
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    const {user, history} = this.props;
    const {loading, showForm} = this.state;
    if (!user) return <div />;
    return (
      <div className={'profile-page'}>
        <div className={'enter-menu profile-page__left'}>
          <a href={routes.main} title="Go to Main page" className={'enter-menu__logo'}>
            {logo()}
            <div className="enter-menu__logo-subtitle">Profile</div>
          </a>
          <div className="profile-page__info">
            <p>Username: <b>{user.username}</b></p>
            {user.name && <p>Name: <b>{user.name}</b></p>}
            {user.email && <p>E-mail: <b>{user.email}</b></p>}
          </div>
          {!showForm ?
            <React.Fragment>
              <Button onClick={() => this.setState({showForm: true})} disabled={loading}>
                Change password
              </Button><br/><br/>
            </React.Fragment> :
            <PasswordChangeForm
              onButtonPress={this.onChangePasswordSubmit}
              onCancelPress={() => this.setState({showForm: false})}
              onInputChange={this.onInputChange}
              disabled={loading}
            />
          }
          <br/><br/>
          <Button onClick={this.goBack} disabled={loading} variant={'secondary'}>
            Go back
          </Button><br/><br/>
          <Button onClick={() => usersActions.logout(history)} disabled={loading} variant={'secondary'}>
            Logout
          </Button><br/><br/>
        </div>
        <div className={'profile-page__right'}>
          <div className='page-content' id='content'>
            <AvailableFiles />
          </div>
        </div>
      </div>
    );
  }
}

ProfilePage.propTypes = {
  history: PropTypes.object,
  dispatch: PropTypes.func,
  user: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    user: state.users.user
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProfilePage));
