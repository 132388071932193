export const checkIfExists = (value) => {
  return value !== undefined && value !== null;
};

export const handleEnterPress = (event, callback) => {
  if (event.key === 'Enter') {
    callback();
    event.preventDefault();
  }
};

export const EVENT_SOURCE_MAP = {
  'IDS': 'Intruder detection system',
  'IR': 'Infrared camera',
  'TEMP_SENSOR': 'Temperature sensor',
  'UNKNOWN': 'Unknown'
};

export const downloadFromUrl = (url) => {
  let element = document.createElement('a');
  element.setAttribute('href', url);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};
