import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {Auth} from 'aws-amplify';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {logo} from '../../components/logo';
import routes from '../../routes';
import './styles.sass';
import SignInForm from '../../components/login_forms/SignIn';
import NewPasswordForm from '../../components/login_forms/NewPassword';
import {usersConstants} from '../../store/constants/users';
import {getUserPayload} from '../../helpers/store';
import PasswordResetForm from '../../components/login_forms/PasswordReset';
import PasswordResetConfirmForm from '../../components/login_forms/PasswordResetConfirm';
import PasswordResetSuccessForm from '../../components/login_forms/PasswordResetSuccess';
import mainImage from '../../images/kyotopia-site.png';

const AUTH_STATES = {
  signIn: 0,
  newPasswordRequired: 1,
  mfaRequired: 2,
  resetPassword: 3,
  resetPasswordConfirmation: 4,
  resetPasswordSuccess: 5
};

export class LoginPage extends Component {

  state = {
    loading: false,
    username: '',
    password: '',
    name: '',
    newPassword1: '',
    newPassword2: '',
    code: '',
    authState: AUTH_STATES.signIn
  }

  user = null;

  constructor(props) {
    super(props);
    this.onInputChange = this.onInputChange.bind(this);
    this.onSignInSubmit = this.onSignInSubmit.bind(this);
    this.onResetButtonPress = this.onResetButtonPress.bind(this);
    this.onNewPasswordSubmit = this.onNewPasswordSubmit.bind(this);
    this.onPasswordResetSubmit = this.onPasswordResetSubmit.bind(this);
    this.onPasswordResetConfirmationSubmit = this.onPasswordResetConfirmationSubmit.bind(this);
    this.backToLogin = this.backToLogin.bind(this);
  }

  resetForms = () => {
    this.setState({
      username: '',
      password: '',
      name: '',
      newPassword1: '',
      newPassword2: '',
    });
  }

  onInputChange = (event) => {
    const { target } = event;
    this.setState({[target.name]: target.value});
  }

  async onSignInSubmit() {
    const {username, password} = this.state;
    if (!username || username === '' || !password || password === '') {
      alert('Please provide username and password');
      return;
    }
    this.setState({ loading: true });
    try {
      this.user = await Auth.signIn(username, password);
      this.setState({ loading: false });
      if (this.user.challengeName === 'SMS_MFA' ||
          this.user.challengeName === 'SOFTWARE_TOKEN_MFA') {
        alert('MFA is not supported yet');
      } else if (this.user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        this.resetForms();
        this.setState({authState: AUTH_STATES.newPasswordRequired});
      } else {
        this.redirectAfterSignIn();
      }
      console.log(this.user);
    } catch (e) {
      // TODO: think about preventing user enumartion
      alert('Cannot login: ' + e.message);
      console.log(e);
      this.setState({ loading: false });
    }
  }

  onResetButtonPress() { this.setState({authState: AUTH_STATES.resetPassword}); }

  async onNewPasswordSubmit() {
    const {newPassword1, newPassword2, name} = this.state;
    if (!newPassword1 || newPassword1 === '' || !newPassword2 || newPassword2 === '' ||
      newPassword1 !== newPassword2) {
      alert('Please provide valid passwords. Password cannot be empty. Passwords must be identical.');
      return;
    }
    this.setState({ loading: true });
    try {
      this.user = await Auth.completeNewPassword(this.user, newPassword1, {name});
      console.log(this.user);
      this.setState({ loading: false });
      if (this.user.challengeName === 'SMS_MFA' ||
        this.user.challengeName === 'SOFTWARE_TOKEN_MFA') {
        alert('MFA is not supported yet');
      } else {
        this.redirectAfterSignIn();
      }
    } catch (e) {
      alert('Cannot set new password: ' + e.message);
      console.log(e);
      this.setState({ loading: false });
    }
  }

  async onPasswordResetSubmit() {
    const {username} = this.state;
    if (!username || username === '') {
      alert('Please provide valid username.');
      return;
    }
    this.setState({ loading: true });
    try {
      await Auth.forgotPassword(username);
      this.setState({
        authState: AUTH_STATES.resetPasswordConfirmation,
        loading: false
      });
    } catch (error) {
      alert(error.message);
      this.setState({ loading: false });
    }
  }

  async onPasswordResetConfirmationSubmit() {
    const { username, code, newPassword1, newPassword2 } = this.state;
    if (!newPassword1 || newPassword1 === '' || !newPassword2 || newPassword2 === '' ||
      newPassword1 !== newPassword2) {
      alert('Please provide valid passwords. Password cannot be empty. Passwords must be identical.');
      return;
    }
    this.setState({ loading: true });

    try {
      await Auth.forgotPasswordSubmit(
        username,
        code,
        newPassword1
      );
      this.setState({
        loading: false,
        authState: AUTH_STATES.resetPasswordSuccess,
      });
      this.resetForms();
    } catch (error) {
      this.setState({ loading: false });
      alert('Error while setting new password: ' + error.message);
      console.log(error);
      this.setState({ loading: false });
    }
  }

  backToLogin() { this.setState({authState: AUTH_STATES.signIn}); }

  redirectAfterSignIn = () => {
    const {dispatch, history} = this.props;
    console.log('redirectAfterSignIn');
    dispatch({type: usersConstants.SET_USER, payload: getUserPayload(this.user)});
    history.replace(routes.main);
  }

  render() {
    const {authState, loading, username} = this.state;
    let form;
    if (authState === AUTH_STATES.signIn) {
      form =
        <SignInForm
          onButtonPress={this.onSignInSubmit}
          onResetPress={this.onResetButtonPress}
          onInputChange={this.onInputChange}
          disabled={loading}
          defaultUsername={username}
        />;
    } else if (authState === AUTH_STATES.newPasswordRequired) {
      form =
        <NewPasswordForm
          onButtonPress={this.onNewPasswordSubmit}
          onInputChange={this.onInputChange}
          onBackPress={this.backToLogin}
          disabled={loading}
        />;
    } else if (authState === AUTH_STATES.resetPassword) {
      form =
        <PasswordResetForm
          onButtonPress={this.onPasswordResetSubmit}
          onInputChange={this.onInputChange}
          onBackPress={this.backToLogin}
          disabled={loading}
          defaultUsername={username}
        />;
    } else if (authState === AUTH_STATES.resetPasswordConfirmation) {
      form =
        <PasswordResetConfirmForm
          onButtonPress={this.onPasswordResetConfirmationSubmit}
          onInputChange={this.onInputChange}
          onBackPress={this.backToLogin}
          disabled={loading}
          defaultUsername={username}
        />;
    }
    else if (authState === AUTH_STATES.resetPasswordSuccess) {
      form =
        <PasswordResetSuccessForm
          onButtonPress={this.backToLogin}
          disabled={loading}
        />;
    }
    return (
      <div className={'login-page'}>
        <div className={'enter-menu login-page__left'}>
          <a href={routes.main} title="Go to Main page" className={'enter-menu__logo'}>
            {logo()}
            <div className="enter-menu__logo-subtitle">Login</div>
          </a>
          {form}
        </div>
        <div className={'login-page__right'}>
          <div className='page-content' id='content'>
            <div className='page-content__splash'>
              <img src={mainImage} alt='Kyotopia Site illustration' />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  history: PropTypes.object,
  dispatch: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    user: state.users.user
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginPage));
