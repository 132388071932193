import * as React from 'react';
import { Range } from 'react-range';
import PropTypes from 'prop-types';

import './styles.sass';

export default class CustomRange extends React.Component {

  render() {
    const {values, onChange, onFinalChange, disabled, max = 100} = this.props;

    return (
      <Range
        step={1}
        min={0}
        max={max}
        values={values}
        onChange={onChange}
        onFinalChange={onFinalChange}
        disabled={disabled}
        renderTrack={({ props, children }) => (
          <div
            {...props}
            className={'track'}
          >
            {children}
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            className={'thumb'}
          />
        )}
      />
    );
  }
}

CustomRange.propTypes = {
  values: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onFinalChange: PropTypes.func,
  disabled: PropTypes.bool,
  max: PropTypes.number
};
