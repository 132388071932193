import React, {Component} from 'react';
import {connect} from 'react-redux';
import {format, fromUnixTime} from 'date-fns';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';

import './styles.sass';
import MenuHeaderBack from '../../../components/operate/MenuHeaderBack';
import {menuActions} from '../../../store/actions/menu';
import BigButton from '../../../components/operate/buttons/BigButton';
import {eventsService} from '../../../store/services/events';
import replayIcon from '../../../images/replay-icon.svg';
import {eventsActions} from '../../../store/actions/events';
import {EVENT_SOURCE_MAP} from '../../../helpers/utils';
import {eventLevel, eventLevelConfig} from '../../../store/constants/events';

export class MenuEventDetails extends Component {

  state = {
    intervalCount: 0,
    textAreaText: '',
    loader: false,
    playing: false,
    controls: true,
    loop: true,
    streamUrl: null
  };

  componentDidMount() {
    const {eventDetails} = this.props;
    eventsService.getEventStream(eventDetails.id).then(response => {
      console.log('Got alert stream url:', response.data);
      this.setState({streamUrl: response.data});
    }).catch(error => {
      if (error.response.status === 404) {
        alert('Video for this event is no longer available!');
      }
      else {
        alert(`Error occured while getting stream: ${error}`);
      }
    });
  }

  componentWillUnmount() {
    const {initializeLiveVideo} = this.props;
    initializeLiveVideo();
  }

  replayVideo = () => {
    this.player.seekTo(0);
    this.player.play();
  };

  setMenuEvents = () => {
    this.props.dispatch(menuActions.setEventsMenu());
  };

  handleChange = (event) => {
    this.setState({textAreaText: event.target.value});
  };

  onSubmitClick = (id, note) => {
    const {dispatch} = this.props;
    if (note === '') {
      alert('Please provide a note!');
      return false;
    } else {
      this.setState({loader: true});
      eventsService.setEventNotes(id, note).then(() => {
        this.setState({textAreaText: '', loader: false});
        dispatch(menuActions.setEventsMenu());
        dispatch(eventsActions.removeEvent(id));
      }).catch((error) => {
        alert(error);
        this.setState({loader: false, textAreaText: ''});
      });
    }
  };

  ref = player => {
    this.player = player;
  };

  render() {
    const {eventDetails, isReadonly} = this.props;
    const {textAreaText, loader, playing, controls, loop, streamUrl} = this.state;
    const level = eventDetails.level;
    const levelConfig = eventLevelConfig[level === undefined ? eventLevel.WARNING:level];
    return (
      <React.Fragment>
        <MenuHeaderBack title={'Event details'} onClick={this.setMenuEvents}/>

        <div className={'event-details__top-row'}>
          <span className="event-details__subtitle">Title</span>
          <span className="event-details__alert" style={{color: levelConfig.color}}>{levelConfig.verbose}</span>
        </div>
        <div className={'event-details__title'}>
          {eventDetails.title}
          {eventDetails.subtitle && <span><br/>{eventDetails.subtitle}</span>}
        </div>
        <div className={'event-details__date'}>
          {format(fromUnixTime(eventDetails.timestamp), 'HH:mm:ss / d MMM')}
        </div>
        <div className="event-details__subtitle">Source</div>
        <div className={'event-details__source'}>
          {EVENT_SOURCE_MAP[eventDetails.source]}
          {eventDetails.source_details && <span>&nbsp;({eventDetails.source_details})</span>}
        </div>
        <div className="event-details__subtitle">Description</div>
        <div className={'event-details__description'}>{eventDetails.description}</div>
        {!isReadonly &&
        <React.Fragment>
          <div className="event-details__subtitle">Add notes</div>
          <textarea name="descr" id="descr" value={textAreaText} placeholder={'Type something…'}
            className={'event-details__textarea'} onChange={this.handleChange} />

          <BigButton text={loader ? false : 'submit'}
            onClick={() => this.onSubmitClick(eventDetails.id, textAreaText)}/>
        </React.Fragment>
        }

        <div className="event-details__video">
          <div className="event-details__video-replay" onClick={this.replayVideo}>
            <img src={replayIcon} alt="Replay" />&nbsp;
            <span>replay</span>
          </div>
          {streamUrl &&
            <ReactPlayer
              ref={this.ref}
              width='100%'
              height='100%'
              url={streamUrl}
              playing={playing}
              controls={controls}
              loop={loop}
            />
          }
        </div>
      </React.Fragment>
    );
  }
}

MenuEventDetails.propTypes = {
  dispatch: PropTypes.func,
  eventDetails: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string,
    subtitle: PropTypes.string,
    timestamp: PropTypes.number,
    description: PropTypes.string,
    level: PropTypes.number,
    source: PropTypes.string,
    source_details: PropTypes.string
  }),
  initializeLiveVideo: PropTypes.func,
  isReadonly: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    eventDetails: state.menu.eventDetails,
    isReadonly: state.general.isReadonly
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuEventDetails);
