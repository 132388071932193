import React, {Component} from 'react';
import RxPlayer from 'rx-player';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import './styles.sass';
import {HeliostatActions} from '../../../store/actions/heliostat';
import {plantService} from '../../../store/services/plant';
import {store} from '../../../store';
import {checkVideoAutoPlay} from '../../../helpers/video';
import Video from '../../../components/operate/Video';
import {hasModuleActive} from '../../../helpers/plant';
import {plantModules} from '../../../store/constants/plant';


export class PlantLivePreview extends Component {

  player = null;
  state = {
    showManualPlay: false,
    videoState: null
  }

  componentDidMount() {
    const {plant, dispatch, setInitializeVideoFunc, streams} = this.props;
    if (hasModuleActive({plant, module: plantModules.HELIOSTATS})) {
      dispatch(HeliostatActions.getHeliostatList());
    }
    if (plant && streams && streams.length > 0) {
      this.initializeVideo();
    }
    setInitializeVideoFunc(this.initializeVideo.bind(this));
  }

  componentDidUpdate(prevProps) {
    const {plant, plantStatus, streams} = this.props;
    if ((prevProps && prevProps.plant && plant && plantStatus && prevProps.plant.id !== plant.id) ||
        (!prevProps || !prevProps.plant) && plant && streams && streams.length > 0) {
      this.delayInitializeVideo();
    }
    if (prevProps && prevProps.streams !== streams && streams && streams.length > 0) {
      console.log('ComponentDidUpdate: delayInitializeVideo');
      this.delayInitializeVideo();
    }
  }

  delayInitializeVideo() {
    checkVideoAutoPlay(
      () => this.initializeVideo(),
      () => this.setState({showManualPlay: true})
    );
  }

  initializeVideo() {
    if (!this.player) {
      const videoElement = document.querySelector('video');
      this.player = new RxPlayer({ videoElement });
      this.player.addEventListener('error', (error) => {
        console.log('Video player error', error);
      });
      this.player.addEventListener('playerStateChange', (state) => {
        console.log('Video player state change', state);
        this.setState({videoState: state});
      });
    }
    this.player.stop();
    this.loadVideo();
  }

  loadVideo() {
    const {plantStatus} = this.props;
    console.log('[PlantLivePreview] Loading new video for', plantStatus);
    //TODO: pass chosenStream as param? stream has device_type to show
    let chosenStream = 0;
    const streamName = store.getState().plant.streams[chosenStream].title;
    this.setState({videoState: 'GETTING_URL'});
    plantService.getStreamUrl(streamName).then(response => {
      console.log(response);
      const streamUrl = response.data;
      console.log('Got stream url', streamUrl);
      this.setState({videoState: 'GETTING_URL_SUCCEEDED'});

      this.player.loadVideo({
        url: streamUrl,
        transport: 'dash',
        autoPlay: true,
      });

    }).catch(error => {
      if (error.response.status === 404){
        alert('Video for this event is no longer available!');
      }
      else {
        alert('Error while getting stream url:' + error);
      }
    });
  }

  manualPlay() {
    this.setState({showManualPlay: false});
    this.initializeVideo();
  }

  render() {
    const {plant} = this.props;
    const {showManualPlay, videoState} = this.state;
    if (!plant) return <div />;

    return (
      <div style={{'backgroundColor': 'black', 'width': '100%'}}>
        <Video
          showManualPlay={showManualPlay}
          manualPlayOnClick={this.manualPlay.bind(this)}
          state={videoState}
        />
      </div>
    );
  }
}

PlantLivePreview.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  menuInfo: PropTypes.bool,
  plant: PropTypes.object,
  plantStatus: PropTypes.object,
  setInitializeVideoFunc: PropTypes.func,
  streams: PropTypes.array
};

const mapStateToProps = (state) => {
  return {
    plant: state.plant.plant,
    plantStatus: state.plant.status,
    menuInfo: state.menu.menuInfo,
    streams: state.plant.streams
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PlantLivePreview));
