import React from 'react';
import {FormCheck} from 'react-bootstrap';
import PropTypes from 'prop-types';

import './styles.sass';
import {UnityProjectDesignCommunication} from '../../../helpers/UnityProjectDesignCommunication';

class AreaSubmenu extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="submenu__item">
          <div className="submenu__item__text">
            <FormCheck>
              <FormCheck.Input onChange={this.props.onChange} checked={this.props.pencilMode} />
              <FormCheck.Label>Pencil mode</FormCheck.Label>
            </FormCheck>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

AreaSubmenu.propTypes = {
  pencilMode: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  unityCommunication: PropTypes.instanceOf(UnityProjectDesignCommunication)
};

export default AreaSubmenu;
