import React, {Component} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import './styles.sass';
import MenuEventsInfo from './MenuEventsInfo';
import MenuEventDetails from './MenuEventDetails';


export class MenuEventsBase extends Component {

  render() {
    const {menuEventsInfo, menuEventsDetails, initializeLiveVideo} = this.props;
    return (
      <div className={'menu-info-wrapper'}>
        <div className="menu-info">
          {menuEventsInfo && <MenuEventsInfo />}
          {menuEventsDetails && <MenuEventDetails initializeLiveVideo={initializeLiveVideo} />}
        </div>
      </div>
    );
  }
}

MenuEventsBase.propTypes = {
  menuEventsInfo: PropTypes.bool,
  menuEventsDetails: PropTypes.bool,
  initializeLiveVideo: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    menuEventsInfo: state.menu.menuEventsInfo,
    menuEventsDetails: state.menu.menuEventsDetails
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuEventsBase);
