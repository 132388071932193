import {socketEventActions, socketChannels, socketConstants} from '../constants/socket';
import config from '../../config';
import {eventsActions} from '../actions/events';
import {plantTesActions} from '../actions/plantTes';
import {menuActions} from '../actions/menu';
import {plantModules} from '../constants/plant';
import {plantActions} from '../actions/plant';
import {HeliostatActions} from '../actions/heliostat';
import {hasModuleActive} from '../../helpers/plant';
import {getCurrentAuthenticatedUser} from '../../helpers/store';

export const socketMiddleware = (() => {
  let socket = null;
  let heartbeat = {
    timer: null,
    lastTimestamp: null
  };
  let closeRequested = null;

  const connect = (store) => {
    if (!config.SOCKET_URL) {
      store.dispatch({type: socketConstants.CONNECTION_ERROR});
      return;
    }
    let url;
    if (config.IS_DEMO) {
      url = config.overrideSocketUrl;
      if (!url) {
        alert('Invalid socket url');
        store.dispatch({type: socketConstants.CONNECTION_ERROR});
        return;
      }
      url += `?Authorization=ApiKey-${config.apiKey}`;
      handleConnect(store, url);
    } else {
      getCurrentAuthenticatedUser(store.dispatch).then(user => {
        url = config.SOCKET_URL + `?Authorization=AccessToken-${user.accessToken}`;
        handleConnect(store, url);
      });
    }
  };

  const handleConnect = (store, url) => {
    console.log('Starting socket connection');
    socket = new WebSocket(url);
    socket.onopen = (() => {
      store.dispatch({type: socketConstants.CONNECTED});
      setHeartbeatTimer(store);
      // use this flag after first connection
      if (closeRequested === null) closeRequested = false;
    });
    socket.onmessage = (event => {
      let data;
      try {
        data = JSON.parse(event.data);
        console.log('Received data from socket:', data);
      } catch (error) {
        console.log('Cannot parse socket event data:', event, error);
        return;
      }
      dispatchEvent(store, data);
    });
    socket.onclose = (() => {
      console.log('Socket disconnected');
      store.dispatch({type: socketConstants.DISCONNECTED});
      if (closeRequested === false) {
        console.log('Socket connection closed by host, trying to reconnect');
        store.dispatch({type: socketConstants.CONNECT});
      } else if (closeRequested) closeRequested = null;
    });
  };

  const dispatchEvent = (store, event) => {
    if (event.action) {
      if (event.action === 'join') {
        store.dispatch({type: socketConstants.JOINED, payload: event.payload});
      }
    } else {
      if (event.channel) {
        if (event.channel.indexOf(socketChannels.HEARTBEAT) !== -1) {
          heartbeat.lastTimestamp = new Date().getTime();
          const selectedPlant = store.getState().plant.plant;
          if (hasModuleActive({plant: selectedPlant, modules: [plantModules.WEATHER, plantModules.HELIOSTATS]})) {
            store.dispatch(plantActions.updateOnline(true));
          }
          if (hasModuleActive({plant: selectedPlant, module: plantModules.TES})) {
            store.dispatch(plantTesActions.updateTesOnline(true));
          }
          setHeartbeatTimer(store);
          return;
        }
        if (event.channel.indexOf(socketChannels.EVENTS) !== -1) {
          console.log('Got alert action:', event.payload);
          switch (event.payload.action) {
            case socketEventActions.NEW:
              store.dispatch(eventsActions.updateEvents(event.payload.data));
              break;
            case socketEventActions.RESOLVE:
              store.dispatch(eventsActions.removeEvent(event.payload.data.id));
              break;
            case socketEventActions.DISMISS_ALL:
              store.dispatch(eventsActions.clearEvents());
              break;
            default:
              console.log('Unrecognized action');
          }
          return;
        }
        if (event.channel.indexOf(socketChannels.TES_STATUS) !== -1) {
          console.log('Got TES status update', event.payload);
          //TODO: try to unify with plant status action and plant tes status action
          if (event.payload.global) {
            let newEmergencyStop = event.payload.global[0].emergency_stop;
            let newErrorStop = event.payload.global[0].error_stop;
            if (newEmergencyStop !== undefined) newEmergencyStop = parseInt(newEmergencyStop);
            if (newErrorStop !== undefined) newErrorStop = parseInt(newErrorStop);
            const actStatus = store.getState().plant.tes.status;
            const actEmergencyStop = parseInt(actStatus.global?.emergency_stop);
            const actErrorStop = parseInt(actStatus.global?.error_stop);
            console.log('NewEmergency', newEmergencyStop, 'NewError', newErrorStop, 'old', actStatus);
            if (actStatus && ((actEmergencyStop === 0 && newEmergencyStop === 1) ||
                (actErrorStop === 0 && newErrorStop === 1)))
              store.dispatch(menuActions.setHaltEmergency());
            if (actStatus && ((actEmergencyStop === 1 && newEmergencyStop === 0) ||
                (actErrorStop === 1 && newErrorStop === 0)))
              store.dispatch(menuActions.setMenuTesInfo());
          }
          store.dispatch(plantTesActions.updateTesStatus(event.payload));
          return;
        }
        if (event.channel.indexOf(socketChannels.STATUS) !== -1) {
          store.dispatch(plantActions.updateStatus(event.payload));
          return;
        }
        if (event.channel.indexOf(socketChannels.HELIOSTAT) !== -1) {
          store.dispatch(HeliostatActions.updateHeliostat(event.payload));
        }
      }
    }
  };

  const getChannelNames = (store, channels) => {
    return channels.map((channel) => {
      const plantId = store.getState().plant.plant.id;
      return `${plantId}#${channel}`;
    });
  };

  const setHeartbeatTimer = (store) => {
    if (heartbeat.timer) clearTimeout(heartbeat.timer);
    heartbeat.timer = setTimeout(() => {
      const selectedPlant = store.getState().plant.plantodules;
      if (hasModuleActive({plant: selectedPlant, modules: [plantModules.HELIOSTATS, plantModules.WEATHER]})) {
        store.dispatch(plantActions.updateOnline(false));
      }
      if (hasModuleActive({plant: selectedPlant, module: plantModules.TES})) {
        store.dispatch(plantTesActions.updateTesOnline(false));
      }
    }, config.HEARTBEAT_TIMEOUT * 1000);
  };

  return store => next => action => {
    let channels;
    switch(action.type) {
      case socketConstants.CONNECT:
        if (socket !== null) {
          console.log('Disconnecting socket');
          socket.close();
        }
        next(action);
        connect(store);
        break;

      case socketConstants.JOIN:
        if (socket === null) { console.warn('Cannot join - empty socket'); break; }
        next(action);
        channels = getChannelNames(store, action.payload);
        console.log('Joining channels', channels);
        socket.send(JSON.stringify({
          action: 'join',
          payload: { channels }
        }));
        break;

      case socketConstants.DISCONNECT:
        if (socket !== null) {
          console.log('Disconnecting socket');
          closeRequested = true;
          socket.close();
        }
        next(action);
        socket = null;
        break;

      default:
        return next(action);
    }
  };
})();
