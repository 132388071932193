import React, {Component} from 'react';
import {connect} from 'react-redux';
import {PropTypes} from 'prop-types';

import './styles.sass';
import MenuHeaderTitle from '../../../components/operate/MenuHeaderTitle';
import ActionConfirm from '../../../components/operate/ActionConfirm';
import {menuActions} from '../../../store/actions/menu';
import {plantService} from '../../../store/services/plant';


const textActionConfirm = `Turning stop mode will turn all heliostats into stow 
                            position and stop the power production of the whole plant.`;

export class MenuHaltBase extends Component {

  onCancel = () => {
    this.props.dispatch(menuActions.toggleMenuHalt());
  };

  onConfirm = () => {
    plantService.haltProduction().then(() => {
      this.props.dispatch(menuActions.setHaltProgress());
    }).catch(() => {
      alert('Error while stopping production');
    });
  };

  render() {
    const {plant} = this.props;

    return (
      <React.Fragment>
        <MenuHeaderTitle subtitle={'Power Plant Name'} title={plant.title}
          online={true}/>

        <ActionConfirm onCancel={this.onCancel} onConfirm={this.onConfirm} text={textActionConfirm}/>
      </React.Fragment>
    );
  }
}

MenuHaltBase.propTypes = {
  dispatch: PropTypes.func,
  plant: PropTypes.object
};


const mapStateToProps = (state) => {
  return {
    plant: state.plant.plant,
    plantStatus: state.plant.status,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuHaltBase);
