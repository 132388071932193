import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Form} from 'react-bootstrap';
import set from 'date-fns/set';
import format from 'date-fns/format';

import './styles.sass';

export class EnergyHoursTable extends Component {

  formatHour = (hour) => {
    const now = new Date();
    const from = set(now, {hours: hour, minutes: 0});
    const to = set(now, {hours: hour + 1, minutes: 0});
    const fromFormatted = format(from, 'HH:mm');
    const toFormatted = format(to, 'HH:mm');
    return `${fromFormatted} - ${toFormatted}`;
  }

  render() {
    const {calculate, item, disabled} = this.props;
    return (
      <tr className="energy-hours-table-row">
        <td>{this.formatHour(item.hour)}</td>
        <td className={'fw-normal'}>
          <Form.Control type="number" value={item.clientLoad} min={0}
            placeholder={0} disabled={disabled}
            onChange={(event) => calculate('clientLoad', event.target.value)}/>
        </td>
        <td className={'fw-normal'}>
          <Form.Control type="number" value={item.renewable} min={0}
            placeholder={0} disabled={disabled}
            onChange={(event) => calculate('renewable', event.target.value)}/>
        </td>
        <td align={'center'}>{item.excessEnergy}</td>
        <td align={'center'}>{item.storageFeed}</td>
        <td align={'center'}>{item.gridDemand}</td>
      </tr>
    );
  }
}

EnergyHoursTable.propTypes = {
  calculate: PropTypes.func,
  item: PropTypes.object,
  disabled: PropTypes.bool
};

export default EnergyHoursTable;
