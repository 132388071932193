import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import './styles.sass';
import MenuHeaderTitle from '../../../components/operate/MenuHeaderTitle';
import BigButton from '../../../components/operate/buttons/BigButton';
import {menuActions} from '../../../store/actions/menu';

export class MenuHaltProgress extends Component {

  componentDidMount() {
    // setTimeout(this.redirect, 1000);
  }

    redirect = () => {
      this.props.dispatch(menuActions.setHaltEmergency());
    };

    render() {
      const {plant} = this.props;

      return (
        <React.Fragment>
          <MenuHeaderTitle subtitle={'Power Plant Name'} title={plant.title}/>

          <BigButton mode onClick={this.onClick}/>
        </React.Fragment>
      );
    }
}

MenuHaltProgress.propTypes = {
  dispatch: PropTypes.func,
  plant: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    plant: state.plant.plant,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuHaltProgress);
