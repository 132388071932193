import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import '../styles.sass';
import './styles.sass';
import MenuHeaderTitle from '../../../../components/operate/MenuHeaderTitle';
import Switch from '../../../../components/operate/Switch';
import Select from '../../../../components/operate/Select';
import Range from '../../../../components/operate/Range';
import {plantTesActions} from '../../../../store/actions/plantTes';
import {plantTesService} from '../../../../store/services/plantTes';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import {getFlowModeById} from 'helpers/store';
import InfoItem from '../../../../components/operate/InfoItem';
import {menuActions} from '../../../../store/actions/menu';
import MenuTanks from '../../../../components/operate/MenuTanks';


const paletteOptions = [
  {value: 1, label: 'Iron Black'}
];

const resolutionOptions = [
  {value: 1, label: 'High (0,01K)'}
];

const grainStateOptions = [
  {value: 1, label: 'High'}
];

const RANGE_MAX_TEMPERATURE = 500;

export class MenuTesInfo extends Component {

  state = {
    maintenanceMode: null,
    flowMode: null,
    power: null,
    rangePower: null,
    temperature: null,
    rangeTemperature: null,
    isChanging: false,
    pallete: paletteOptions[0],
    resolution: resolutionOptions[0],
    grainState: grainStateOptions[0]
  };

  canRender() {
    const {plant, modes, status} = this.props;
    const {maintenanceMode, power, flowMode} = this.state;
    return plant && modes && status && maintenanceMode !== null && power && flowMode;
  }

  componentDidMount() {
    const {status, dispatch} = this.props;
    dispatch(plantTesActions.getModes());
    const temperature = [status && status.heater && status.heater.desired_temperature ?
      parseInt(status.heater.desired_temperature) : null];
    const power = [status && status.pump && status.pump.power ? parseInt(status.pump.desired_power) : null];
    this.setState({
      maintenanceMode: status?.global?.is_maintenance,
      power,
      rangePower: power,
      flowMode: this.getModeById(status?.global?.flow_mode),
      temperature,
      rangeTemperature: temperature
    });
  }

  componentDidUpdate() {
    const {maintenanceMode, flowMode, power, temperature, isChanging} = this.state;
    const {status, modes} = this.props;
    
    // propagate first values for controls
    const newState = {};
    if ((maintenanceMode === null || maintenanceMode === undefined) && status && status.global)
      newState.maintenanceMode = status.global.is_maintenance;
    if (!flowMode && status && status.global && modes) {
      let newFlowMode = this.getModeById(status.global.flow_mode);
      if (newFlowMode) newState.flowMode = newFlowMode;
    }
    if ((power === null || power[0] === null) && status && status.pump) {
      newState.power = [parseInt(status.pump.desired_power)];
      newState.rangePower = newState.power;
    }

    if (temperature === null || temperature[0] === null && status && status.heater) {
      newState.temperature = [parseInt(status.heater.desired_temperature)];
      newState.rangeTemperature = newState.temperature;
    }

    if (Object.keys(newState).length > 0) {
      this.setState(newState);
    }

    // waiting for changes
    // TODO:: define how heater temperature should be confirmed
    console.log('componentDidUpdate', isChanging, status.global, status.pump, status.heater, status.tank,
      maintenanceMode, flowMode, power);
    if (isChanging &&
      Boolean(status?.global?.is_maintenance) === Boolean(maintenanceMode) &&
      status?.global?.flow_mode === parseInt(flowMode.value) &&
      status?.pump?.power === power[0] &&
      status?.heater?.desired_temperature === status?.tank[0]?.temperature
    ) {
      this.setState({isChanging: false});
    }
  }

  onMaintenanceModeChange(checked) {
    const {modes} = this.props;
    const newState = {maintenanceMode: checked};
    if (checked === true) this.setState({flowMode: modes['maintenance'][0]});
    this.setState(newState);
    plantTesService.setMaintenanceMode(checked).then(response => {
      console.log('SetMaintenanceMode', response);
    }).catch(error => {
      this.setState({
        isChanging: false,
        maintenanceMode: !checked
      });
      alert('Error while changing tes mode: ' + error);
    });
  }

  onFlowModeChange(mode) {
    const prevMode = this.state.flowMode;
    this.setState({flowMode: mode});
    const confirmed = window.confirm(`Are you sure you want to set flow mode to '${mode.label}'?`);
    if (confirmed) {
      plantTesService.setFlowMode(mode.value).then(response => {
        console.log('SetFlowMode', response);
      }).catch(error => {
        alert('Error while changing tes flow mode: ' + error);
        this.setState({isChanging: false, flowMode: prevMode});
      });
    } else {
      this.setState({isChanging: false, flowMode: prevMode});
    }
  }

  onPowerChange(power) {
    this.setState({rangePower: power});
  }

  onPowerFinalChange(power) {
    const confirmed = window.confirm(`Are you sure you want to set pump power to ${power[0]}%?`);
    const prevPower = this.state.power;
    if (confirmed) {
      plantTesService.setPumpPower(power[0]).then(response => {
        this.setState({power, isChanging: false});
        console.log('SetPumpPower', response);
      }).catch(error => {
        alert('Error while changing pump power: ' + error);
        this.setState({rangePower: prevPower, isChanging: false});
      });
    } else {
      this.setState({rangePower: prevPower, isChanging: false});
    }
  }

  onTemperatureChange(temperature) {
    this.setState({rangeTemperature: temperature});
  }

  onTemperatureFinalChange(temperature) {
    const confirmed = window.confirm(`Are you sure you want to set temperature to ${temperature[0]}°C?`);
    const prevTemperature = this.state.temperature;
    if (confirmed) {
      plantTesService.setTankTemperature(temperature[0]).then(response => {
        this.setState({temperature, isChanging: false});
        console.log('SetTankTemperature', response);
      }).catch(error => {
        alert('Error while changing tank temperature: ' + error);
        this.setState({rangeTemperature: prevTemperature, isChanging: false});
      });
    } else {
      this.setState({rangeTemperature: prevTemperature, isChanging: false});
    }
  }

  getModeById(modeId) {
    const {modes, status} = this.props;
    if (!status || !status.global) return;
    return getFlowModeById(modes, status.global.is_maintenance, modeId);
  }

  getTankTemperature(temperatureArray) {
    if (!temperatureArray) return;
    return temperatureArray.reduce((a, b) => a + parseInt(b.value), 0) / temperatureArray.length;
  }

  toggleTanksMenu() { this.props.dispatch(menuActions.toggleMenuTanks()); }
  selectTank(tank) {
    const {dispatch, selectedTank} = this.props;
    if (selectedTank && selectedTank.object_id === tank.object_id) dispatch(plantTesActions.selectTank(null));
    else dispatch(plantTesActions.selectTank(tank));
  }
  selectValve(valve) {
    const {dispatch, selectedValve} = this.props;
    if (selectedValve && selectedValve.object_id === valve.object_id) dispatch(plantTesActions.selectValve(null));
    else dispatch(plantTesActions.selectValve(valve));
  }

  render() {
    const {plant, modes, status, liveVideo, streams, selectedStream, isReadonly, menuTanks,
      selectedTank, selectedValve} = this.props;
    const {maintenanceMode, power, flowMode, isChanging, pallete, resolution, grainState, rangePower,
      rangeTemperature} = this.state;
    console.log('Render, plant:', plant, 'modes:', modes, 'status:', status, 'maintenanceMode:', maintenanceMode,
      'power:', power, 'flowMode:', flowMode);

    if (!this.canRender()) return <div />;
    const flowModeObj = this.getModeById(status.global.flow_mode);
    const lastSeen = status.global?.timestamp ? new Date(parseInt(status.global.timestamp) * 1000) : new Date();
    const lastSeenDistance = formatDistanceStrict(new Date(), lastSeen);
    const showIrCameraOptions = liveVideo && streams && streams[selectedStream] &&
        streams[selectedStream].title.indexOf('vulcanus') !== -1;

    return (
      <React.Fragment>
        <div className={'menu-info-wrapper'}>
          <div className="menu-info p-0">
            <div className={'block pb-0'}>
              <MenuHeaderTitle subtitle={'TES details'} title={plant.title} online={status.online} />
            </div>
            <hr/>
            <div className={'block item'}>
              <div className={'clearfix mb-3'}>
                <div className={'item__text float-left'}>
                  TES Status
                  <div className={'item__small-text'}>Current status</div>
                </div>
                <div className={'item__value float-right'}>
                  {status.global && !status.global.is_maintenance ? 'Operational':'Maintenance'}
                  <div className={'item__small-text'}>{`${lastSeenDistance} ago`}</div>
                </div>
              </div>
              {!isReadonly &&
              <div className={clsx('clearfix', isChanging && 'is-changing')}>
                <div className={clsx('maintenance-text', 'float-left', maintenanceMode && 'maintenance-text--on')}>
                  Maintenance mode
                </div>
                <div className={'item__switch float-right'}>
                  <Switch
                    height={28}
                    width={60}
                    onChange={this.onMaintenanceModeChange.bind(this)}
                    checked={Boolean(maintenanceMode)}
                    disabled={isChanging}
                  />
                </div>
              </div>
              }
            </div>
            <hr/>
            <div className={'block item'}>
              <div className={'clearfix'}>
                <div className={'item__text float-left'}>Current Mode</div>
                <div className={'item__value float-right'}>
                  {flowModeObj && flowModeObj.label}
                </div>
              </div>
              {!isReadonly &&
              <div className={clsx('mt-3', isChanging && 'is-changing')}>
                <Select
                  value={flowMode}
                  options={modes ? modes[(maintenanceMode ? 'maintenance':'operational')]:[]}
                  onChange={this.onFlowModeChange.bind(this)}
                  isDisabled={isChanging}
                />
              </div>
              }
            </div>
            <hr/>
            <div className={'block item'}>
              <div className={'clearfix'}>
                <InfoItem
                  title={'Tanks and valves'}
                  subtitle={'Temperature'}
                  data={'>'}
                  onClick={this.toggleTanksMenu.bind(this)}
                />
              </div>
              <div className={'clearfix mt-2'}>
                <div className={'item__text float-left'}>State of charge</div>
                <div className={'item__value float-right'}>
                  {status.global?.soc?.error_code !== 0 ? 'N/A':(parseInt(status.global.soc.value) + '%')}
                </div>
              </div>
              <div className={'clearfix mt-3'}>
                <div className={'item__text float-left'}>Heat control</div>
                <div className={'item__value float-right'}>{status?.heater?.heater_on ? 'Active':'Inactive'}</div>
              </div>
              {!isReadonly &&
              <div className={clsx('item__range', 'clearfix', isChanging && 'is-changing')}>
                <div className={'item__label'}>Set temperature</div>
                <div className={'item__range__slider float-left'}>
                  <Range
                    values={rangeTemperature}
                    onChange={this.onTemperatureChange.bind(this)}
                    onFinalChange={this.onTemperatureFinalChange.bind(this)}
                    max={RANGE_MAX_TEMPERATURE}
                    disabled={isChanging}
                  />
                </div>
                <div className={'item__range__value float-right'}>
                  {rangeTemperature && rangeTemperature[0]}
                  <sup>o</sup>C
                </div>
              </div>
              }
            </div>
            <hr />
            <div className={'block item'}>
              <div className={'clearfix'}>
                <div className={'item__text float-left'}>Pump</div>
                <div className={'item__value float-right'}>
                  {status.pump.active ? 'Active':'Inactive'}&nbsp;/&nbsp;
                  {status.pump ? parseInt(status.pump.power):0}%
                </div>
              </div>
              {!isReadonly &&
              <div className={clsx('item__range', 'clearfix', isChanging && 'is-changing')}>
                <div className={'item__label'}>Set power</div>
                <div className={'item__range__slider float-left'}>
                  <Range
                    values={rangePower}
                    onChange={this.onPowerChange.bind(this)}
                    onFinalChange={this.onPowerFinalChange.bind(this)}
                    disabled={status.pump && status.pump.active === false || isChanging}
                  />
                </div>
                <div className={'item__range__value float-right'}>{rangePower && rangePower[0]}%</div>
              </div>
              }
            </div>
            <hr />
            <div className={'block item'}>
              <div className={'clearfix'}>
                <div className={'item__text float-left'}>
                  IR Camera<br/>
                  <span className={'item__subtext'}>Current status</span>
                </div>
                <div className={'item__value float-right'}>
                  {status.camera && status.camera.active ? 'Active':'Inactive'}
                </div>
              </div>
            </div>
            {!isReadonly && showIrCameraOptions &&
              <React.Fragment>
                <div className={'block item clearfix pt-0'}>
                  <div className={'item__label'}>
                    Pseudo color palette
                  </div>
                  <div className={'mt-2'}>
                    <Select
                      value={pallete}
                      options={paletteOptions}
                      onChange={() => console.log('Changing pallete')}
                      isDisabled={!status.camera.active}
                    />
                  </div>
                </div>
                <div className={'block item clearfix pt-0'}>
                  <div className={'item__label'}>
                    Radiometry tlinear resolution
                  </div>
                  <div className={'mt-2'}>
                    <Select
                      value={resolution}
                      options={resolutionOptions}
                      onChange={() => console.log('Changing resolution')}
                      isDisabled={!status.camera.active}
                    />
                  </div>
                </div>
                <div className={'block item clearfix pt-0'}>
                  <div className={'item__label'}>
                    Grain state
                  </div>
                  <div className={'mt-2'}>
                    <Select
                      value={grainState}
                      options={grainStateOptions}
                      onChange={() => console.log('Changing grain state')}
                      isDisabled={!status.camera.active}
                    />
                  </div>
                </div>
              </React.Fragment>
            }
          </div>
        </div>
        { menuTanks &&
          <MenuTanks
            close={this.toggleTanksMenu.bind(this)}
            valves={status.valve}
            tanks={status.tank}
            selectedTank={selectedTank}
            selectTank={this.selectTank.bind(this)}
            selectedValve={selectedValve}
            selectValve={this.selectValve.bind(this)}
          />
        }
      </React.Fragment>
    );
  }
}

MenuTesInfo.propTypes = {
  plant: PropTypes.object,
  modes: PropTypes.object,
  status: PropTypes.object,
  dispatch: PropTypes.func,
  liveVideo: PropTypes.bool,
  streams: PropTypes.array,
  selectedStream: PropTypes.number,
  isReadonly: PropTypes.bool,
  menuTanks: PropTypes.bool,
  selectedTank: PropTypes.object,
  selectedValve: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    plant: state.plant.plant,
    modes: state.plant.tes.modes,
    status: state.plant.tes.status,
    liveVideo: state.menu.liveVideo,
    streams: state.plant.streams,
    selectedStream: state.plant.selectedStream,
    isReadonly: state.general.isReadonly,
    menuTanks: state.menu.menuTanks,
    selectedTank: state.plant.selectedTank,
    selectedValve: state.plant.selectedValve
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuTesInfo);
