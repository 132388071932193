import React from 'react';

export const eventsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23"
    viewBox="0 0 22 23">
    <g transform="translate(-21 -75)">
      <path
        d="M40.29 90.35c.344 0 .625.28.625.622 0 .342-.28.622-.628.622H23.702a.624.624 0 0 1-.627-.622c0-.342.28-.622.624-.622 1.03 0 1.865-.837 1.865-1.867v-4.978a6.429 6.429 0 0 1 6.43-6.43 6.429 6.429 0 0 1 6.431 6.43v4.978c0 1.032.835 1.867 1.865 1.867zm-6.423 3.33a1.872 1.872 0 0 1-1.862 1.66 1.872 1.872 0 0 1-1.862-1.66zm6.43 0A2.702 2.702 0 0 0 43 90.98c0-1.418-1.1-2.583-2.49-2.69V83.51C40.51 78.81 36.7 75 32 75c-4.7 0-8.51 3.809-8.51 8.51v4.781a2.7 2.7 0 0 0-2.49 2.69 2.7 2.7 0 0 0 2.703 2.698h4.358a3.944 3.944 0 0 0 7.878 0z"/>
    </g>
  </svg>
);

export const infoIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="20"
    viewBox="0 0 26 20">
    <g transform="translate(-19 -142)">
      <path id="8rboa"
        d="M45 155v.01a1.042 1.042 0 0 1-1.048 1.03h-3.067a1.061 1.061 0 0 1-1.06-1.057v-.03a1.063 1.063 0 0 1 1.064-1.068h1.823a10.72 10.72 0 0 0-2.354-5.666l-1.23 1.229c-.429.43-1.13.43-1.56 0l-.016-.016a1.107 1.107 0 0 1 0-1.56l1.23-1.23a10.718 10.718 0 0 0-5.667-2.353v1.792c0 .606-.492 1.098-1.097 1.098h-.032a1.101 1.101 0 0 1-1.101-1.102v-1.788a10.717 10.717 0 0 0-5.667 2.352l1.23 1.23a1.107 1.107 0 0 1 0 1.561l-.034.034a1.08 1.08 0 0 1-1.525 0l-1.248-1.248a10.729 10.729 0 0 0-2.354 5.667h1.828c.587 0 1.063.477 1.061 1.064v.03a1.065 1.065 0 0 1-1.065 1.061h-3.064A1.042 1.042 0 0 1 19 155.01V155c0-7.168 5.832-13 13-13s13 5.832 13 13m-10.036-4.167l-6.873 5.704a4.5 4.5 0 0 0-.626.72c-1.91 2.75 2.056 5.933 4.327 3.473.214-.232.406-.49.568-.766l4.083-7.944c.558-.952-.67-1.938-1.479-1.188"/>
    </g>
  </svg>
);

export const cameraIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24"
    viewBox="0 0 26 24">
    <g transform="translate(-20 -662)">
      <path
        d="M43.374 674.192l-4.292-2.46v-3.28l4.292-2.461zm-6.889.957a.42.42 0 0 1-.41.41H23.007a.42.42 0 0 1-.41-.41v-10.115a.42.42 0 0 1 .41-.41h13.068a.42.42 0 0 1 .41.41zm8.228-11.509a2.52 2.52 0 0 0-2.488 0l-3.144 1.805v-.438A2.998 2.998 0 0 0 36.074 662H23.007A2.998 2.998 0 0 0 20 665.007v10.115a2.998 2.998 0 0 0 3.007 3.007h3.582l-2.816 5.714a1.265 1.265 0 0 0 .601 1.722 1.265 1.265 0 0 0 1.723-.601l3.389-6.835h.137l3.39 6.835c.218.464.683.711 1.175.711.191 0 .382-.028.574-.137a1.294 1.294 0 0 0 .602-1.722l-2.87-5.66h3.581a2.998 2.998 0 0 0 3.007-3.007v-.438l3.144 1.805c.382.219.82.328 1.258.328.438 0 .848-.11 1.258-.328a2.44 2.44 0 0 0 1.258-2.16l-.001-8.529a2.598 2.598 0 0 0-1.286-2.187z"/>
    </g>
  </svg>
);

export const haltIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="29" height="26"
    viewBox="0 0 29 26">
    <g>
      <g transform="translate(-18 -731)">
        <path id="lf0ia"
          d="M37.264 738.478c-.532 0-.945.414-.945.887v3.723a.454.454 0 0 1-.443.443.454.454 0 0 1-.444-.443v-5.053c0-.502-.443-.916-.945-.916s-.945.413-.945.916v5.023a.454.454 0 0 1-.443.443.454.454 0 0 1-.443-.443v-5.792c0-.502-.444-.916-.946-.916a.927.927 0 0 0-.945.916v5.792a.454.454 0 0 1-.443.443.454.454 0 0 1-.443-.443v-5.023c0-.502-.443-.916-.945-.916a.927.927 0 0 0-.946.916v7.031c0 .354-.502.473-.65.148l-.886-1.743a.952.952 0 0 0-1.27-.384c-.474.236-.68.797-.444 1.24l2.747 5.585h.03a3.66 3.66 0 0 0 3.133 1.773h3.898a3.663 3.663 0 0 0 3.663-3.663v-8.657c0-.502-.443-.887-.945-.887z"/>
      </g>
      <g transform="translate(-18 -731)">
        <path id="lf0ib"
          d="M43.914 746.133l-4.017 6.977a4.266 4.266 0 0 1-3.693 2.127h-8.038c-1.507 0-2.924-.827-3.693-2.127l-4.017-6.977a4.271 4.271 0 0 1 0-4.286l4.018-6.976a4.266 4.266 0 0 1 3.694-2.128h8.036c1.507 0 2.924.828 3.693 2.128l4.019 6.976c.767 1.33.767 2.986-.002 4.286zm1.508-5.118l-4.02-6.972a6.016 6.016 0 0 0-5.198-3.013h-8.038a6.054 6.054 0 0 0-5.2 2.985l-4.018 7a6.034 6.034 0 0 0 0 5.999l4.019 6.973c1.064 1.861 3.043 2.985 5.2 2.985h8.037a6.054 6.054 0 0 0 5.2-2.985l4.019-6.973c1.062-1.862 1.062-4.166-.001-5.999z"/>
      </g>
    </g>
  </svg>
);

export const closeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9">
    <g transform="rotate(-45 227.701 637.746)">
      <path
        d="M517.11 27.02a.5.5 0 0 1 1 0v5.09h5.08a.5.5 0 0 1 0 1h-5.08v5.08a.5.5 0 0 1-1 0v-5.08h-5.09a.5.5 0 0 1 0-1h5.09z"/>
    </g>
  </svg>
);

export const lightningIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="10"
    viewBox="0 0 6 10">
    <g transform="translate(-515 -231)">
      <path fill={'red'} id="gonca"
        d="M517.636 237.182l-2.636-.377 4.072-5.805-.526 3.727 2.454.42-4.033 5.853z"/>
    </g>
  </svg>
);

export const keyIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12"
    viewBox="0 0 12 12">
    <g transform="translate(-486 -102)">
      <path fill="#ffce00"
        d="M497.835 104.482a.101.101 0 0 0-.166-.049l-1.66 1.66a.572.572 0 0 1-.811 0l-1.328-1.318a.572.572 0 0 1 0-.81l1.66-1.66c.059-.06.03-.147-.049-.167a3.419 3.419 0 0 0-3.379.86 3.437 3.437 0 0 0-.79 3.642l-4.884 4.883c-.566.567-.576 1.494 0 2.041a1.424 1.424 0 0 0 1.993-.02l4.902-4.902a3.441 3.441 0 0 0 3.643-.79 3.428 3.428 0 0 0 .869-3.37z"/>
    </g>
  </svg>
);

export const plantOfflineIcon = () => (
  <svg width="77px" height="64px" viewBox="0 0 77 64" xmlns="http://www.w3.org/2000/svg">
    <g id="OPERATE" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="KYOTO-5-UI-OFF-LINE" transform="translate(-714.000000, -248.000000)" fill="#FF0000" fillRule="nonzero">
        <g id="offline" transform="translate(714.000000, 248.000000)">
          <path d="M6.5592,38.1592 C6.5592,32.9592 10.79984,28.6408 16.0776,28.6408 C16.396352,28.6408 16.636976,28.722048 16.95888,28.722048 L17.35888,28.803296 C18.95888,29.04392 20.39952,28.003296 20.71824,26.484576 C22.55888,18.803296 29.43664,13.443776 37.35904,13.443776 C37.918416,13.443776 38.47776,13.525024 39.04032,13.525024 L41.84032,7.684384 C40.39968,7.365632 38.88096,7.203136 37.35904,7.203136 C27.51824,7.203136 18.87744,13.362496 15.51824,22.403136 C7.19984,22.721888 0.47744,29.603136 0.47744,38.084736 C0.47744,46.725536 7.51808,53.766336 16.15904,53.766336 L19.84032,53.766336 L22.79968,47.606976 L16.15904,47.606976 C10.79968,47.681976 6.55904,43.360096 6.55904,38.159776 L6.5592,38.1592 Z" id="Path"></path>
          <path d="M56.8816,18.0816 C55.60032,16.16288 54.0816,14.40032 52.40032,12.96288 L56.40032,4.64448 C57.119072,3.12576 56.481568,1.28512 54.95968,0.5632 C53.44096,-0.155552 51.60032,0.481952 50.8784,2.00384 L23.4408,59.44144 C22.722048,60.96016 23.359552,62.8008 24.88144,63.52272 C25.28144,63.763344 25.76272,63.841472 26.2408,63.841472 C27.35952,63.841472 28.48144,63.200848 29.0408,62.082112 L32.95952,53.841312 L58.55952,53.841312 C68.40032,53.841312 76.47792,45.841312 76.47792,35.922912 C76.3997952,25.519712 67.44032,17.041312 56.88112,18.082112 L56.8816,18.0816 Z M58.4816,47.6816 L35.8408,47.6816 L49.6,18.8 C50.71872,20 51.75936,21.35936 52.48128,22.88128 C53.121904,24.16256 54.48128,24.8 55.92192,24.48128 C56.8032,24.240656 57.68128,24.162528 58.48128,24.162528 C64.96256,24.162528 70.24048,29.443808 70.24048,35.921728 C70.24048,42.399648 64.9592,47.680928 58.48128,47.680928 L58.4816,47.6816 Z" id="Shape"></path>
        </g>
      </g>
    </g>
  </svg>
);

export const schemeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
    <g>
      <g>
        <g>
          <path fill="#a9bbd6" d="M6.5 21.125c0-.898-.727-1.625-1.625-1.625h-3.25C.727 19.5 0 20.227 0 21.125v3.25C0 25.273.727 26 1.625 26h3.25c.898 0 1.625-.727 1.625-1.625z"/>
        </g>
        <g>
          <path fill="#a9bbd6" d="M26 21.125c0-.898-.727-1.625-1.625-1.625h-3.25c-.898 0-1.625.727-1.625 1.625v3.25c0 .898.727 1.625 1.625 1.625h3.25c.898 0 1.625-.727 1.625-1.625z"/>
        </g>
        <g>
          <path fill="#a9bbd6" d="M6.5 1.625C6.5.727 5.773 0 4.875 0h-3.25C.727 0 0 .727 0 1.625v3.25C0 5.773.727 6.5 1.625 6.5h3.25c.898 0 1.625-.727 1.625-1.625z"/>
        </g>
        <g>
          <path fill="#a9bbd6" d="M26 1.625C26 .727 25.273 0 24.375 0h-3.25C20.227 0 19.5.727 19.5 1.625v3.25c0 .898.727 1.625 1.625 1.625h3.25C25.273 6.5 26 5.773 26 4.875z"/>
        </g>
        <g>
          <path fill="#a9bbd6" d="M4.88 8.13H1.63v9.75h3.25v-3.25h3.25v-3.25H4.88z"/>
        </g>
        <g>
          <path fill="#a9bbd6" d="M21.13 17.88h3.25V8.13h-3.25v3.25h-3.25v3.25h3.25z"/>
        </g>
        <g>
          <path fill="#a9bbd6" d="M16.25 11.375c0-.898-.727-1.625-1.625-1.625h-3.25c-.898 0-1.625.727-1.625 1.625v3.25c0 .898.727 1.625 1.625 1.625h3.25c.898 0 1.625-.727 1.625-1.625z"/>
        </g>
      </g>
    </g>
  </svg>
);

export const irCameraIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24">
    <g>
      <g>
        <path fill="#a9bbd6" d="M23.374 12.192l-4.292-2.46v-3.28l4.292-2.461zm-6.889.957a.42.42 0 0 1-.41.41H3.007a.42.42 0 0 1-.41-.41V3.034a.42.42 0 0 1 .41-.41h13.068a.42.42 0 0 1 .41.41zM24.713 1.64a2.52 2.52 0 0 0-2.488 0l-3.144 1.805v-.438A2.998 2.998 0 0 0 16.074 0H3.007A2.998 2.998 0 0 0 0 3.007v10.115a2.998 2.998 0 0 0 3.007 3.007H6.59l-2.816 5.714a1.265 1.265 0 0 0 .601 1.722 1.265 1.265 0 0 0 1.723-.601l3.389-6.835h.137l3.39 6.835c.218.464.683.711 1.175.711.191 0 .382-.028.574-.137a1.294 1.294 0 0 0 .602-1.722l-2.87-5.66h3.581a2.998 2.998 0 0 0 3.007-3.007v-.438l3.144 1.805c.382.219.82.328 1.258.328.438 0 .848-.11 1.258-.328A2.44 2.44 0 0 0 26 12.356l-.001-8.529a2.598 2.598 0 0 0-1.286-2.187z"/>
      </g>
    </g>
  </svg>
);
