import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import '../styles.sass';

// TECHDEBT: move styles to component
// TECHDEBT: create more generic component for tables
const getValveRow = (valve, index, isSelected, selectValve) => {
  // cols: id, state, solenoid, sensor top, sensor bottom
  let rowClass, statusVerbose;
  if (valve.state === 0) {
    rowClass = 'table__row--empty';
    statusVerbose = 'CLOSED';
  } else if (valve.state === 1) {
    rowClass = 'table__row--success';
    statusVerbose = 'OPEN';
  }  else if (valve.state === 2) {
    rowClass = 'table__row--success';
    statusVerbose = 'CHANGING';
  } else {
    rowClass = 'table__row--error';
    statusVerbose = 'ERROR';
  }

  return (
    <tr className={clsx('table__row', {'table__row--selected': isSelected}, rowClass)}
      key={`valve-${index}`} onClick={() => selectValve(valve)}>
      <td scope="row">{valve.object_id}</td>
      <td>{statusVerbose}</td>
      <td className={'table__cell'}>{valve.solenoid ? 'ON':'OFF'}</td>
      <td className={'table__cell'}>{valve.sensor_top}</td>
      <td className={'table__cell'}>{valve.sensor_bottom}</td>
    </tr>
  );
};

const isSelected = (selectedValve, valve) => {
  if (!selectedValve) return false;
  return selectedValve.object_id === valve.object_id;
};

const ValvesTable = ({valves, selectedValve, selectValve}) => (
  <div className={'table'}>
    <div className="table__title">Valves</div>
    <table>
      <thead>
        <tr>
          <th scope="col">ID</th>
          <th scope="col">State</th>
          <th scope="col" className={'table__cell'}>Solenoid</th>
          <th scope="col" className={'table__cell'}>Top sensor</th>
          <th scope="col" className={'table__cell'}>Bottom sensor</th>
        </tr>
      </thead>
      {
        valves && valves.length > 0 ? (
          <tbody>
            {
              valves.sort((a, b) => parseInt(a.object_id) - parseInt(b.object_id)).
                map((valve, index) =>
                  (getValveRow(valve, index, isSelected(selectedValve, valve), selectValve)))
            }
          </tbody>) : <tbody><tr><td>No valves</td></tr></tbody>
      }
    </table>
  </div>
);

ValvesTable.propTypes = {
  valves: PropTypes.array,
  selectedValve: PropTypes.object,
  selectValve: PropTypes.func.isRequired
};

export default ValvesTable;
