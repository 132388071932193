export const plantConstants = {
  GETTING_LIST: 'PLANT_GETTING_LIST',
  GET_LIST_SUCCEEDED: 'PLANT_GET_LIST_SUCCEEDED',
  GET_LIST_ERROR: 'PLANT_GET_LIST_ERROR',
  GET_STREAMS_SUCCEEDED: 'PLANT_GET_STREAMS_SUCCEEDED',
  SELECT: 'PLANT_SELECT',
  GET_STATUS_SUCCEEDED: 'PLANT_GET_STATUS_SUCCEEDED',
  GET_STREAM_URL_SUCCEEDED: 'PLANT_GET_STREAM_URL_SUCCEEDED',
  NEXT_STREAM: 'PLANT_NEXT_STREAM',
  UPDATE_ONLINE: 'PLANT_UPDATE_ONLINE',
  UPDATE_STATUS: 'PLANT_UPDATE_STATUS'
};

export const plantUrls = {
  GET_LIST: '/plant/list',
  GET_STATUS: '/plant',
  RESUME_PRODUCTION: '/plant/resume-production',
  HALT_PRODUCTION: '/plant/halt-production',
  GET_STREAMS: '/plant/<plantId>/get-streams',
  GET_STREAM_URL: '/plant/<plantId>/get-stream-url'
};

export const plantStatus = {
  0: 'Operational',
  1: 'Stopped',
  2: 'Manually stopped'
};

export const plantStatusTypes = {
  OPERATIONAL: 0,
  HALTED: 1,
  MANUALLY_HALTED: 2,
  ERROR_HALTED: 3
};

export const plantModules = {
  HELIOSTATS: 0,
  TES: 1,
  WEATHER: 2
};
