import React, {Component} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import '../../../containers/operate/menu/styles.sass';
import './styles.sass';
import {arrowLeft} from '../../arrows';

export class MenuHeaderBack extends Component {
  /**
   * Header menu
   * allowe variants: grey, black
   */

  render() {
    const {title, onClick, variant} = this.props;
    return (
      <div className={clsx('header-back', {'header-back--black': variant === 'black'})}>
        <div className="header-back__title">{title}</div>
        <div className={'enter-menu__item-back'} onClick={onClick}>{arrowLeft()} back</div>
      </div>
    );
  }
}

MenuHeaderBack.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.string
};

export default MenuHeaderBack;
