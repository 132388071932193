export const MenuDesign = {
  DESIGN_MENU_SET_COLLAPSE: 'DESIGN_MENU_SET_COLLAPSE'
};

export const MenuInfo = {
  TOGGLE_MENU_INFO: 'TOGGLE_MENU_INFO',
  TOGGLE_MENU_TES_INFO: 'TOGGLE_MENU_TES_INFO',
  TOGGLE_INNER_MENU: 'TOGGLE_INNER_MENU',
  CLOSE_INNER_MENU: 'CLOSE_INNER_MENU',
  SET_MENU_INFO_PLANT: 'SET_MENU_INFO_PLANT',
  SET_MENU_TES_INFO: 'SET_MENU_TES_INFO',
  SET_MENU_INFO_HELIOSTAT: 'SET_MENU_INFO_HELIOSTAT',
  SET_MENU_INFO_MAINTENANCE: 'SET_MENU_INFO_MAINTENANCE',
  SET_MENU_INFO_PRODUCTION: 'SET_MENU_INFO_PRODUCTION',
  SET_LIVE_VIDEO: 'MENU_SET_LIVE_VIDEO',
  UNSET_LIVE_VIDEO: 'MENU_UNSET_LIVE_VIDEO',
  TOGGLE_MENU_TANKS: 'TOGGLE_MENU_TANKS',
  SHOW_MENU_TANKS: 'SHOW_MENU_TANKS'
};

export const MenuHalt = {
  TOGGLE_MENU_HALT: 'TOGGLE_MENU_HALT',
  SET_HALT_PRODUCTION: 'SET_HALT_PRODUCTION',
  SET_HALT_PROGRESS: 'SET_HALT_PROGRESS',
  SET_HALT_EMERGENCY: 'SET_HALT_EMERGENCY',
};

export const MenuEvents = {
  TOGGLE_MENU_EVENTS: 'TOGGLE_MENU_EVENTS',
  SET_MENU_EVENTS: 'SET_MENU_EVENTS',
  SET_MENU_DETAILS: 'SET_MENU_DETAILS',
};
