export const plantTesConstants = {
  GET_TES_STATUS_SUCCEEDED: 'GET_TES_STATUS_SUCCEEDED',
  UPDATE_TES_STATUS: 'UPDATE_TES_STATUS',
  GET_MODES_SUCCEEDED: 'GET_MODES_SUCCEEDED',
  UPDATE_TES_ONLINE: 'UPDATE_TES_ONLINE',
  SELECT_TANK: 'SELECT_TANK',
  SELECT_VALVE: 'SELECT_VALVE'
};

export const plantTesUrls = {
  GET_STATUS: '/plant/<plantId>/tes',
  GET_MODES: '/plant/<plantId>/tes/modes',
  SET_FLOW_MODE: '/plant/<plantId>/tes/set-flow-mode',
  SET_MAINTENANCE_MODE: '/plant/<plantId>/tes/set-maintenance-mode',
  SET_PUMP_POWER: '/plant/<plantId>/tes/set-pump-power',
  SET_TANK_TEMPERATURE: '/plant/<plantId>/tes/set-tank-temperature'
};
