import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import '../styles.sass';


// TECHDEBT: move styles to component
// TECHDEBT: create more generic component for tables
const getTemperatureRow = (temperature, index) => (
  <tr className="table___row table__row--expanded" key={`temperature-${index}`}>
    <td scope="row">{temperature.id}</td>
    <td className="table__cell">
      {temperature.value !== undefined && temperature.error_code === 0 ?
        `${temperature.value}°C`:'N/A'}
    </td>
    <td className="table__cell" />
  </tr>
);

const getTankRow = (tank, index, isSelected, selectTank) => (
  <React.Fragment>
    <tr className={clsx('table__row', {'table__row--selected': isSelected})}
      key={`tank-${index}`} onClick={() => selectTank(tank)}>
      <td scope="row">{tank.object_id}</td>
      <td className="table__cell">
        {tank.average_temperature !== undefined && tank.average_temperature.error_code === 0 ?
          `${tank.average_temperature.value}°C`:'N/A'}
      </td>
      <td className="table__cell">
        {tank.fill_volume !== undefined && tank.fill_volume.error_code === 0 ?
          `${(100 * tank.fill_volume.value).toFixed(2)}%`:'N/A'}
      </td>
    </tr>
    {isSelected && tank.temperature && typeof tank.temperature[Symbol.iterator] === 'function' ?
      tank.temperature.map(getTemperatureRow):null}
  </React.Fragment>
);

const isSelected = (selectedTank, tank) => {
  if (!selectedTank) return false;
  return selectedTank.object_id === tank.object_id;
};

const TanksTable = ({tanks, selectedTank, selectTank}) => (
  <div className={'table'}>
    <div className="table__title">Tanks</div>
    <table>
      <thead>
        <tr>
          <th scope="col" className="table__cell">ID</th>
          <th scope="col" className="table__cell">Temperature</th>
          <th scope="col" className="table__cell">Filling volume</th>
        </tr>
      </thead>
      {
        tanks && tanks.length > 0 ? (
          <tbody>
            {
              tanks.sort((a, b) => parseInt(a.object_id) - parseInt(b.object_id)).
                map((tank, index) => (getTankRow(tank, index, isSelected(selectedTank, tank), selectTank)))
            }
          </tbody>) : <tbody><tr><td>No tanks</td></tr></tbody>
      }
    </table>
  </div>
);

TanksTable.propTypes = {
  tanks: PropTypes.array,
  selectedTank: PropTypes.object,
  selectTank: PropTypes.func.isRequired
};

export default TanksTable;
