import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import './styles.sass';
import {usersActions} from '../../store/actions/users';
import routes from '../../routes';

const UserMenu = ({show, username, history}) => (
  <div className={clsx('user-menu', {'user-menu--show': show})}>
    <p className="user-menu__title">{username.replace('@', ' @')}</p>
    <a className="user-menu__item" onClick={() => history.push(routes.profile)}>My profile</a>
    <a className="user-menu__item" onClick={() => usersActions.logout(history)}>Logout</a>
  </div>
);

UserMenu.propTypes = {
  show: PropTypes.bool.isRequired,
  username: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired
};

export default UserMenu;
