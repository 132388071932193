import {plantConstants} from '../constants/plant';
import {plantTesConstants} from '../constants/plantTes';
import config from '../../config';

const initialStage = {
  loadingPlants: false,
  loadingPlantsError: null,
  plants: [],
  streams: [],
  selectedStream: 0,
  status: {},
  heliostats: [],
  tes: {
    modes: {},
    status: {}
  },
  selectedTank: null,
  selectedValve: null
};

export const getOptions = (valuesList) => {
  let options = [];
  for (let option of valuesList) {
    options.push({value: option.id, label: option.mode_name, isDisabled: option.is_readonly,
      schemaUrl: option.schema_url});
  }
  return options;
};

const prepareTesStatus = (status) => {
  return {
    ...status,
    pump: status.pump ? status.pump[0]:{},
    global: status.global ? status.global[0]:{},
    camera: status.camera ? status.camera[0]:{},
    heater: status.heater ? status.heater[0]:{}
  };
};

const mockTesStatus = (deviceName, newStatus) => {
  if (deviceName === 'tank') {
    newStatus[deviceName].fill_volume = {
      error_code: 0,
      value: Math.random()
    };
    newStatus[deviceName].average_temperature = {
      value: (100 + (400 - 100) * Math.random()).toFixed(0),
      error_code: 0
    };
  }
};

const updateTesStatus = (status, statusUpdates) => {
  const newStatus = {
    ...status
  };
  const singleObjectKeys = ['pump', 'global', 'camera', 'heater'];
  for (let deviceName of Object.keys(statusUpdates)) {
    const newObject = statusUpdates[deviceName][0];
    if (singleObjectKeys.includes(deviceName)) {
      newStatus[deviceName] = newObject;
      if (config.MOCK_DATA) mockTesStatus(deviceName, newStatus);
    } else {
      const newObjects = [];
      let found = false;
      for (let object of newStatus[deviceName]) {
        if (object.object_id === newObject.object_id) {
          newObjects.push(newObject);
          found = true;
        }
        else newObjects.push(object);
        if (config.MOCK_DATA) mockTesStatus(deviceName, newStatus);
      }
      if (!found) newObjects.push(newObject);
      newStatus[deviceName] = newObjects;
    }
  }
  return newStatus;
};

export const plant = (state = initialStage, action) => {
  let status = {};
  let isOnline;
  switch (action.type) {
    case plantConstants.GETTING_LIST:
      return {
        ...state,
        loadingPlants: true,
        loadingPlantsError: null
      };
    case plantConstants.GET_LIST_ERROR:
      return {
        ...state,
        loadingPlants: false,
        loadingPlantsError: action.payload
      };
    case plantConstants.GET_LIST_SUCCEEDED:
      return {
        ...state,
        plants: action.payload,
        loadingPlants: false,
        loadingPlantsError: null
      };
    case plantConstants.GET_STREAMS_SUCCEEDED:
      return {
        ...state,
        streams: action.payload,
        selectedStream: 0
      };
    case plantConstants.NEXT_STREAM:
      if (state.streams && state.streams.length === 0) return state;
      return {
        ...state,
        selectedStream: (state.selectedStream + 1) % state.streams.length
      };
    case plantConstants.SELECT:
      return {
        ...state,
        streams: [],
        plant: action.payload,
        status: {}
      };
    case plantConstants.GET_STATUS_SUCCEEDED:
      return {
        ...state,
        status: {
          ...state.status,
          ...action.payload
        }
      };
    case plantConstants.UPDATE_ONLINE:
      isOnline = action.payload;
      return {
        ...state,
        status: {
          ...state.status,
          online: isOnline
        }
      };
    case plantConstants.GET_STREAM_URL_SUCCEEDED:
      return {
        ...state,
        status: {
          ...state.status,
          stream_url: action.payload
        }
      };
    case plantTesConstants.GET_MODES_SUCCEEDED:
      return {
        ...state,
        tes: {
          ...state.tes,
          modes: {
            operational: getOptions(action.payload['operational']),
            maintenance: getOptions(action.payload['maintenance'])
          }
        }
      };
    case plantTesConstants.GET_TES_STATUS_SUCCEEDED:
      // TODO: for MVP we showing very specified info but back-end is prepared for more complicated structures
      status = prepareTesStatus(action.payload);
      return {
        ...state,
        tes: {
          ...state.tes,
          status
        }
      };
    case plantTesConstants.UPDATE_TES_STATUS:
      status = updateTesStatus(state.tes.status, action.payload);
      return {
        ...state,
        tes: {
          ...state.tes,
          status
        }
      };
    case plantTesConstants.UPDATE_TES_ONLINE:
      isOnline = action.payload;
      return {
        ...state,
        tes: {
          ...state.tes,
          status: {
            ...state.tes.status,
            online: isOnline
          }
        }
      };
    case plantConstants.UPDATE_STATUS:
      return {
        ...state,
        status: {
          ...state.status,
          ...action.payload
        }
      };
    case plantTesConstants.SELECT_TANK:
      return {
        ...state,
        selectedTank: action.payload,
        selectedValve: null,
      };
    case plantTesConstants.SELECT_VALVE:
      return {
        ...state,
        selectedValve: action.payload,
        selectedTank: null
      };
    default:
      return state;
  }
};

