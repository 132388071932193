import React from 'react';
import PropTypes from 'prop-types';
import './styles.sass';
import MenuHeaderBack from '../MenuHeaderBack';
import TanksTable from '../tables/TanksTable';
import ValvesTable from '../tables/ValvesTable';

const MenuTanks = ({close, tanks, valves, selectedTank, selectTank, selectedValve, selectValve}) => {
  return (
    <div className="menu-tanks">
      <MenuHeaderBack
        title="Tanks and valves"
        onClick={close}
        variant="black"
      />
      <TanksTable
        tanks={tanks}
        selectedTank={selectedTank}
        selectTank={selectTank}
      />
      <hr />
      <ValvesTable
        valves={valves}
        selectedValve={selectedValve}
        selectValve={selectValve}
      />
    </div>
  );
};

MenuTanks.propTypes = {
  close: PropTypes.func.isRequired,
  tanks: PropTypes.array.isRequired,
  valves: PropTypes.array.isRequired,
  selectedTank: PropTypes.object,
  selectTank: PropTypes.func.isRequired,
  selectedValve: PropTypes.object,
  selectValve: PropTypes.func.isRequired
};

export default MenuTanks;
