import axios from 'axios';
import {plantTesUrls} from '../constants/plantTes';
import {getPlantId} from '../../helpers/store';

export const plantTesService = {
  getStatus,
  getModes,
  setMaintenanceMode,
  setFlowMode,
  setPumpPower,
  setTankTemperature,
  loadSchema
};

function getStatus() {
  return axios.request({
    method: 'GET',
    url: plantTesUrls.GET_STATUS.replace('<plantId>', getPlantId())
  });
}

function getModes() {
  return axios.request({
    method: 'GET',
    url: plantTesUrls.GET_MODES.replace('<plantId>', getPlantId())
  });
}

function setMaintenanceMode(isMaintenance) {
  return axios.request({
    method: 'POST',
    url: plantTesUrls.SET_MAINTENANCE_MODE.replace('<plantId>', getPlantId()),
    data: {
      maintenance: isMaintenance
    }
  });
}

function setFlowMode(mode) {
  return axios.request({
    method: 'POST',
    url: plantTesUrls.SET_FLOW_MODE.replace('<plantId>', getPlantId()),
    data: { mode }
  });
}

function setPumpPower(power) {
  return axios.request({
    method: 'POST',
    url: plantTesUrls.SET_PUMP_POWER.replace('<plantId>', getPlantId()),
    data: { power }
  });
}

function setTankTemperature(temperature) {
  return axios.request({
    method: 'POST',
    url: plantTesUrls.SET_TANK_TEMPERATURE.replace('<plantId>', getPlantId()),
    data: { temperature }
  });
}

function loadSchema(url) {
  // using fetch because axios always add authorization header
  return fetch(url).then(response => response.text());
}
