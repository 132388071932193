import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {ReactSVGPanZoom, TOOL_AUTO} from 'react-svg-pan-zoom';
import {OPERATE_STYLES} from '../../../styles/constants';
import SchemaMenu from '../SchemaMenu';
import {svgPanZoomBaseConfig} from './config';
import {plantTesService} from '../../../store/services/plantTes';
import InlineSVG from 'react-inlinesvg';


class Schema extends Component {
  viewer = React.createRef();
  firstLoad = true;
  state = {
    value: {},
    tool: TOOL_AUTO,
    width: null,
    height: null,
    svgContent: null,
    svgSize: {}
  }

  constructor(props) {
    super(props);
    this.changeTool = this.changeTool.bind(this);
    this.fitToViewer = this.fitToViewer.bind(this);
    this._calculateViewerDimensions = this._calculateViewerDimensions.bind(this);
  }

  componentDidMount() {
    this._calculateViewerDimensions();
    window.addEventListener('resize', this._calculateViewerDimensions);
    this._loadSvg();
  }

  componentDidUpdate(prevProps) {
    const {menuVisible, status, url} = this.props;
    if (prevProps && prevProps.menuVisible !== menuVisible) this._calculateViewerDimensions();
    if (prevProps && prevProps.url !== url) this._loadSvg();
    if (status.global) this._updateSvg();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._calculateViewerDimensions);
  }

  changeTool(tool) {
    this.setState({tool});
  }

  fitToViewer() {
    if (!this.viewer.current) return;
    this.viewer.current.fitToViewer('center', 'center');
  }

  _loadSvg() {
    const {url} = this.props;
    plantTesService.loadSchema(url).then(data => {
      const svgContent = data;
      const parser = new DOMParser();
      const doc = parser.parseFromString(svgContent,'image/svg+xml');
      const element = doc.getElementsByTagName('svg')[0];
      let width = element.getAttribute('width');
      if (width.includes('px')) width = width.substring(0, width.length - 2);
      let height = element.getAttribute('height');
      if (height.includes('px')) height = height.substring(0, height.length - 2);
      this.setState({
        svgSize: {width, height},
        svgContent
      });
      this._tryFitAfterLoad();
      this._updateSvg();
      this._setSchemaListeners();
    }).catch(error => {
      console.log('Error while loading schema', error);
      alert('Cannot load schema!');
    });
  }

  _calculateViewerDimensions() {
    const {menuVisible} = this.props;
    this.setState({
      width: window.innerWidth - OPERATE_STYLES.MENU_WIDTH - (menuVisible ? OPERATE_STYLES.MENU_DETAILS_WIDTH:0),
      height: window.innerHeight
    });
  }

  _tryFitAfterLoad() {
    if (!this.firstLoad || !this.viewer.current) return;
    this.firstLoad = false;
    this.viewer.current.fitToViewer('center', 'center');
    // There is no callback if schema has been rendered
    // this is not the best solution but it's working
    setTimeout(this._updateSvg.bind(this), 100);
  }

  _updateSvg() {
    const {valve, tank, pipe, pump} = this.props.status;
    // TODO: optimisation (change only when needed)?
    for (let device of valve.concat(tank, pipe, pump)) {
      if (!device) continue;
      if (device.temperature && typeof device.temperature[Symbol.iterator] == 'function') {
        for (let temperature of device.temperature) {
          this._updateSvgTemperature(`${temperature.id}temperature`, temperature);
        }
      }
      if (device.fill_volume) {
        const fillVolume = device.fill_volume;
        if (fillVolume.error_code && parseInt(fillVolume.error_code) !== 0) {
          this._updateSvgTankFill(device.object_id, 0, 'N/A');
        } else {
          const fillVolumeNumber = parseFloat(fillVolume.value);
          const fillVolumePercent = (fillVolumeNumber * 100).toFixed(2);
          this._updateSvgTankFill(device.object_id, fillVolumeNumber, `${fillVolumePercent} %`);
        }
      }
      if (device.average_temperature) {
        this._updateSvgTemperature(`${device.object_id}_tank_avg_temperature_value`, device.average_temperature);
        this._updateSvgTemperatureIndicator(`${device.object_id}_tank_avg_temperature`, device.average_temperature);
      }
    }
    this._updateHighlight();
  }

  _updateSvgTemperature(elementId, temperatureObject) {
    let text = document.getElementById(elementId);
    let verbose, verbosePrefix;
    verbosePrefix = temperatureObject.id ? `${temperatureObject.id} `: '';
    if (temperatureObject.error_code && parseInt(temperatureObject.error_code) !== 0) {
      verbose = `${verbosePrefix} N/A`;
    } else {
      verbose = `${verbosePrefix}${temperatureObject.value} °C`;
    }
    if (text && text.children[0]) text.children[0].textContent = verbose;
  }

  _updateSvgTankFill(tankId, volume, verbose) {
    const tankBorder = document.getElementById(`${tankId}_tank_border`);
    const tankFill = document.getElementById(`${tankId}_tank_fill`);
    if (tankBorder && tankFill) {
      const tankHeight = parseFloat(tankBorder.getAttribute('height'));
      const tankY = parseFloat(tankBorder.getAttribute('y'));
      const newFillHeight = tankHeight * volume;
      const newFillY = tankY + (tankHeight - newFillHeight);
      tankFill.setAttribute('height', newFillHeight.toString());
      tankFill.setAttribute('y', newFillY.toString());
    }
    const tankFillValue = document.getElementById(`${tankId}_tank_fill_value`);
    if (tankFillValue && tankFillValue.children[0]) tankFillValue.children[0].textContent = verbose;
  }

  _updateSvgTemperatureIndicator(elementPrefix, temperatureObject) {
    const elementBorder = document.getElementById(`${elementPrefix}_border`);
    const elementIcon = document.getElementById(`${elementPrefix}_icon`);
    if (!elementBorder || !elementIcon) return;
    let color, opacity = 0;
    if (temperatureObject.error_code !== 0) { color = 'grey'; opacity = 1; }
    else {
      const temperature = parseFloat(temperatureObject.value);
      if (temperature > 350) { color = '#ff0000'; }
      else if (temperature > 250) { color = 'orange'; }
      else if (temperature > 180) { color = 'yellow'; }
      else { color = 'grey'; opacity = 1; }
    }
    elementBorder.setAttribute('fill', color);
    elementBorder.setAttribute('stroke', color);
    elementIcon.setAttribute('opacity', opacity.toString());
  }

  _updateHighlight() {
    const {selectedTank, selectedValve} = this.props;
    const elements = document.getElementsByClassName('object-highlight');
    for (let element of elements) element.setAttribute('opacity', '0');
    if (selectedTank) {
      const element = document.getElementById(`${selectedTank.object_id}_highlight`);
      if (element) element.setAttribute('opacity', '1');
    }
    if (selectedValve) {
      const element = document.getElementById(`${selectedValve.object_id}_highlight`);
      if (element) element.setAttribute('opacity', '1');
    }
  }

  _setSchemaListeners() {
    const schemaSvg = document.getElementById('schema');
    if (!schemaSvg) return;
    schemaSvg.addEventListener('click', (data) => {
      const target = data.target;
      if (!target) return;
      const classList = target.classList;
      const objId = target.id;
      if (!objId) return;
      const realObjId = objId.split('_')[0];
      const {status, selectTank, selectValve} = this.props;
      if (classList.contains('clickable-tank')) {
        console.log('Clicked on tank', realObjId);
        const {tank: tanks} = status;
        const tank = tanks.find(el => el.object_id === realObjId);
        if (!tank) return;
        selectTank(tank);
      } else if (classList.contains('clickable-valve')) {
        console.log('Clicked on valve', realObjId);
        const {valve: valves} = status;
        const valve = valves.find(el => el.object_id === realObjId);
        if (!valve) return;
        selectValve(valve);
      }
    });
  }

  render() {
    const {tool, value, width, height, svgContent, svgSize} = this.state;
    if (!width || !height || !svgContent) return <div/>;
    return (
      <ReactSVGPanZoom
        {...svgPanZoomBaseConfig}
        ref={this.viewer}
        width={width} height={height}
        tool={tool}
        onChangeTool={tool => this.setState({tool})}
        value={value}
        onChangeValue={value => this.setState({value})}
        customToolbar={
          (props) =>
            <SchemaMenu tool={props.tool} changeTool={this.changeTool} fitToViewer={this.fitToViewer}/>
        }
      >
        <svg width={svgSize.width} height={svgSize.height}>
          <InlineSVG src={svgContent} />
        </svg>
      </ReactSVGPanZoom>
    );
  }
}

Schema.propTypes = {
  url: PropTypes.string.isRequired,
  status: PropTypes.object.isRequired,
  menuVisible: PropTypes.bool,
  selectedTank: PropTypes.object,
  selectTank: PropTypes.func.isRequired,
  selectedValve: PropTypes.object,
  selectValve: PropTypes.func.isRequired
};

export default Schema;
