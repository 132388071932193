import React, {Component} from 'react';
import { format, fromUnixTime } from 'date-fns';
import PropTypes from 'prop-types';

import './styles.sass';
import {eventLevel, eventLevelConfig} from '../../../store/constants/events';

export class AlertEventItem extends Component {

  render() {
    const {event: {title, timestamp, level}, onClick} = this.props;
    let timestampText;
    try {
      timestampText = format(fromUnixTime(timestamp), 'HH:mm:ss / d MMM');
    } catch {
      timestampText = 'Invalid date';
    }
    const levelConfig = eventLevelConfig[level === undefined ? eventLevel.WARNING:level];

    return (
      <div className={'alert-item'} onClick={onClick}>
        <div className={'alert-item__alert'} style={{color: levelConfig.color}}>{levelConfig.verbose}</div>
        <div className={'alert-item__title'}>{title}</div>
        <div className={'alert-item__footer'}>
          <div className="alert-item__date">
            {timestampText}
          </div>
          <div className="alert-item__button">details</div>
        </div>
      </div>
    );
  }
}

AlertEventItem.propTypes = {
  event: PropTypes.object,
  onClick: PropTypes.func
};

export default AlertEventItem;
