import {plantTesService} from '../services/plantTes';
import {plantTesConstants} from '../constants/plantTes';
import {store} from '../../store';
import {menuActions} from './menu';

export const plantTesActions = {
  getStatus,
  getModes,
  updateTesStatus,
  updateTesOnline,
  selectTank,
  selectValve
};

// TECHDEBT: helios and tes plants should use same statuses

function getStatus() {
  return (dispatch) => {
    return plantTesService.getStatus().then(response => {
      const status = response.data;
      if (!status.global) {
        status.online = false;
      } else {
        emergencyButtonReaction(dispatch, status);
      }
      dispatch({
        type: plantTesConstants.GET_TES_STATUS_SUCCEEDED, payload: status
      });
    });
  };
}

function isEmergencyStop(globalStatus) {
  return globalStatus.emergency_stop && parseInt(globalStatus.emergency_stop) !== 0;
}

function isErrorStop(globalStatus) {
  return globalStatus.error_stop && parseInt(globalStatus.error_stop) !== 0;
}

function isOperational(globalStatus) {
  return !isEmergencyStop(globalStatus) && !isErrorStop(globalStatus);
}

function emergencyButtonReaction(dispatch, status) {
  if (!isOperational(status.global[0])) {
    dispatch(menuActions.setHaltEmergency());
  }
}

function getModes() {
  return (dispatch) => {
    return plantTesService.getModes().then(response => {
      const modes = response.data;
      dispatch({
        type: plantTesConstants.GET_MODES_SUCCEEDED, payload: modes
      });
    });
  };
}

function updateTesStatus(statusUpdate) {
  return {type: plantTesConstants.UPDATE_TES_STATUS, payload: statusUpdate};
}

function updateTesOnline(isOnline) {
  return (dispatch) => {
    const prevStatus = store.getState().plant.tes.status;
    isOnline = true;
    if (prevStatus.online !== isOnline) {
      dispatch({type: plantTesConstants.UPDATE_TES_ONLINE, payload: isOnline});
    }
    if ((!prevStatus.online || prevStatus.online === true) && isOnline === false) {
      dispatch(menuActions.setHaltEmergency());
    }
    if ((prevStatus.online === undefined || prevStatus.online === false) && isOnline === true &&
        isOperational(prevStatus.global)) {
      dispatch(menuActions.setMenuTesInfo());
    }
  };
}

function selectTank(tank) {
  return (dispatch) => {
    dispatch({type: plantTesConstants.SELECT_TANK, payload: tank});
    dispatch(menuActions.showMenuTanks());
  };
}

function selectValve(valve) {
  return (dispatch) => {
    dispatch({type: plantTesConstants.SELECT_VALVE, payload: valve});
    dispatch(menuActions.showMenuTanks());
  };
}
