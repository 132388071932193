export const hasModuleActive = ({plant, module, modules, checkAll = false}) => {
  /**
   * Check if module is active in plant
   * :param plant: plant status object
   * :param module: module from plantModules constant
   * :param modules: array of modules
   * :param checkAll: if true all modules have to be active
   * :return: true if module/modules are active, false if not
   *
   * If module is provided then function do simple check.
   * If modules are provided then one of them needs to be active.
   */
  if (!plant || !Array.isArray(plant.modules)) return false;
  if (module !== null && module !== undefined) {
    return plant.modules.includes(module);
  }
  if (Array.isArray(modules)) {
    if (checkAll) {
      return modules.every((_module) => plant.modules.includes(_module));
    } else {
      return plant.modules.some((_module) => modules.includes(_module));
    }
  }
  return false;
};
