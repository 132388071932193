import React from 'react';
import { Redirect, Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { store } from '../../store';
import {getCurrentAuthenticatedUser} from '../../helpers/store';

class PrivateRoute extends React.Component {
  state = {
    loading: true,
    isAuthenticated: null
  };

  componentDidMount() {
    const { dispatch } = store;
    // const isAuthenticated = store.getState().users.isAuthenticated;
    console.log('Private route checking auth');
    getCurrentAuthenticatedUser(dispatch).then(
      () => {
        this.setState({ loading: false, isAuthenticated: true });
      }
    ).catch(err => {
      console.log('PrivateRoute:', err);
      this.setState({ loading: false, isAuthenticated: false });
    });
  }

  render() {
    const { component: Component, ...rest } = this.props;
    const { loading, isAuthenticated } = this.state;
    console.log(loading, isAuthenticated);
    if (loading || isAuthenticated === null) return (<div/>);
    return (
      <Route
        {...rest}
        render={props => isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
        }
      />
    );
  }
}

PrivateRoute.propTypes = {
  component: PropTypes.object
};

export default withRouter(PrivateRoute);


