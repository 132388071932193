import React, {Component} from 'react';
import {Alert, Col, Container, Row} from 'react-bootstrap';
import Unity, {UnityContent} from 'react-unity-webgl';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {UnityProjectDesignCommunication} from '../../helpers/UnityProjectDesignCommunication';
import './siteDesign.sass';
import UnityMenu from '../../containers/design/UnityMenu';
import config from '../../config';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {menuActions} from '../../store/actions/menu';

export class SiteDesign extends Component {
  unityCommunication = null;

  state = {
    loading: true,
    loadingMap: false,
    progress: 0,
    timePrediction: 'around 60 seconds'
  };

  maxInitialLoadingTime = 30000;
  unityLoadingTimeStart = 0;
  shouldStopExecution = false;
  loadingTimeoutHandler = null;

  constructor(props) {
    super(props);
    const jsonUrl = config.UNITY_URL + '/Kyotopia.json';
    const unityLoaderUrl = config.UNITY_URL + '/UnityLoader.js';
    console.log('Loading Unity: ', jsonUrl, unityLoaderUrl);
    this.unityContent = new UnityContent(
      jsonUrl,
      unityLoaderUrl,
      {
        webglContextAttributes: {preserveDrawingBuffer: true},
      }
    );

    this.unityCommunication = new UnityProjectDesignCommunication(this.unityContent);

    this.unityContent.on('progress', (progress) => {
      if (progress === 0){
        this.unityLoadingTimeStart = Date.now();
        this.loadingTimeoutHandler = setTimeout(this.confirmLoading, this.maxInitialLoadingTime);
      }
      console.log('progress', progress);
    });

    this.unityContent.on('loaded', this.onUnityContentLoaded.bind(this));

    this.unityCommunication.addMapLoadedListener(this.onMapLoaded.bind(this));
  }

  componentDidMount() {
    this.props.dispatch(menuActions.setDesignMenuCollapse(true));
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
    document.removeEventListener('keyup', this.onKeyUp);
  }

  onUnityContentLoaded() {
    const {projectLocation} = this.props;
    console.log('loaded. loadSite = ' + typeof this.loadSite);

    let loadingTime = Date.now() - this.unityLoadingTimeStart;
    console.log(`Loading Unity took ${loadingTime} milliseconds`);
    if (this.shouldStopExecution) {
      return;
    }
    else
      clearTimeout(this.loadingTimeoutHandler);

    // TODO: Talk about this
    console.log('Wait 5s for Unity');
    setTimeout(() => {
      console.log('Setting project location', projectLocation);
      this.unityCommunication.setLocation(projectLocation);
      this.loadSite();
      this.setState({loading: false, loadingMap: true});
    }, 5000);
  }

  onMapLoaded() {
    this.setState({loadingMap: false});
    document.addEventListener('keydown', this.onKeyDown);
    document.addEventListener('keyup', this.onKeyUp);
  }

  confirmLoading = () => {
    let confirmed = window.confirm('Performance issues detected! ' +
      'Loading the 3D view may take several minutes and later performance may not be smooth. ' +
      'Click Cancel to go back to editing your project. ' +
      'We recommend that you enter the 3D view on another machine. ' +
      'Click OK if you want to continue loading.');
    if (!confirmed){
      // TODO: implement elegant solution to stop Unity script execution
      try {
        this.unityCommunication.forceStopUnity();
      }
      catch (e) {
        console.log(e);
        // Unity scripts fail while Unity closes
      }
      this.shouldStopExecution = true;
      setTimeout(()=>{ window.history.back(); }, 100);
    }
    else {
      this.setState({timePrediction: 'several minutes'});
    }
  };

  loadSite = () => {
    const {unityData} = this.props;
    this.unityCommunication.loadProjectFromData(unityData?.data || '{}');
  };

  onKeyDown = (event) => {
    this.unityCommunication.sendKeyPressed(event.code);
  };

  onKeyUp = (event) => {
    this.unityCommunication.sendKeyReleased(event.code);
  };

  render() {
    const { site, projectLocation, settings } = this.props;
    const { loading, loadingMap } = this.state;
    const isReadonly = settings.readonly;

    console.log('siteDesign', site, projectLocation);
    if (!site || !projectLocation) {
      return (
        <Container className="form-page">
          <h1>Design the site</h1>
          <Row><Col><Alert variant="warning">
            Please select a location and describe the project first in the two previous steps.
          </Alert></Col></Row>
        </Container>
      );
    }
    return (
      <div className="form-page relative">
        {(loading || loadingMap) &&
          <div className={clsx('unity-loading', {'unity-loading--transparent': loadingMap})}>
            {loading && 'Loading 3D view...'}
            {loadingMap && 'Loading map...'}
            <br/><br/>
            This may take {this.state.timePrediction}!<br/>
            Use scroll to manipulate zoom.<br/>
            Use shift to manipulate camera angle.<br/>
          </div>
        }
        {!loading && !isReadonly &&
          <UnityMenu unityCommunication={this.unityCommunication} />
        }
        <Unity unityContent={this.unityContent} />
      </div>
    );
  }
}

SiteDesign.propTypes = {
  dispatch: PropTypes.func,
  site: PropTypes.object,
  projectLocation: PropTypes.object,
  settings: PropTypes.object,
  unityData: PropTypes.object,
  projectEnergyProperty: PropTypes.func,
};


const mapStateToProps = (state) => {
  const project = state.projects.project;
  return {
    settings: project?.settings,
    projectLocation: project?.projectLocation,
    site: project?.site,
    projectEnergyProperty: project?.projectEnergyProperty,
    unityData: project?.unityData
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SiteDesign));
