import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@mdi/react';
import { Card, Row, Col, FormCheck, Collapse, Button } from 'react-bootstrap';
import { mdiInformationOutline } from '@mdi/js';
import './ProjectControlChecklistCard.sass';

export const ProjectControlChecklistCard = ({ id, mdiIcon, label, handleChange, helpContents, checked, disabled }) => {
  const [showHelp, toggleHelp] = useState(false);
  const icon = mdiIcon || mdiInformationOutline;
  return (
    <Card className="control-checklist-card">
      <Row>
        <Col md={10} sm={9}>
          <FormCheck.Input id={id} type="checkbox" onChange={handleChange} checked={checked} disabled={disabled} />
          <FormCheck.Label htmlFor={id}>{label}</FormCheck.Label>
          <Collapse in={showHelp}>
            <div className="control-checklist-card__help-text">{helpContents || ''}</div>
          </Collapse>
        </Col>
        <Col md={2} sm={3} style={{ textAlign: 'right' }}>
          <Button variant="link" className="control-checklist-help" onClick={() => toggleHelp(!showHelp)}>
            <Icon path={icon} size='1rem' />
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

ProjectControlChecklistCard.propTypes = {
  id: PropTypes.string,
  mdiIcon: PropTypes.string,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  handleChange: PropTypes.func,
  helpContents: PropTypes.string,
  disabled: PropTypes.bool
};
