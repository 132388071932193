import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {lightningIcon} from '../../svg';
import '../../../containers/operate/menu/styles.sass';

export class MenuHeaderTitle extends Component {

  render() {
    const {online, subtitle, title} = this.props;
    let onlineComponent;
    if (online !== null) {
      onlineComponent = online ?
        <div className="menu-info__online">online <span className={'green-circle'}/></div> :
        ( online === false ? <div className="menu-info__offline">offline {lightningIcon()}</div>:
          <div className="menu-info__connecting">connecting <span className={'blue-circle'}/></div>
        );
    }

    return (
      <div className={'menu-info__header'}>
        {onlineComponent}
        <div className="menu-info__subtitle">{subtitle}</div>
        <div className="menu-info__title">{title}</div>
      </div>
    );
  }
}

MenuHeaderTitle.propTypes = {
  online: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string
};

export default MenuHeaderTitle;
