import React from 'react';
import {Form} from 'react-bootstrap';
import PropTypes from 'prop-types';

import './styles.sass';

class StorageSubmenu extends React.Component {
  onChange(event) {
    const {onChange} = this.props;
    const eventTarget = event.target;
    if (eventTarget.value && eventTarget.max && parseInt(eventTarget.value) > parseInt(eventTarget.max)) return;
    if (eventTarget.value && eventTarget.min && parseInt(eventTarget.value) < parseInt(eventTarget.min)) return;
    onChange(eventTarget.name, eventTarget.value);
  }

  render() {
    const {params} = this.props;

    return (
      <React.Fragment>
        <div className="submenu__item">
          <div className="submenu__item__text">
            <Form.Text>Storage type</Form.Text>
          </div>
          <div className="submenu__item__input">
            <Form.Control as="select" value={params.type}
              onChange={this.onChange.bind(this)}
              name="type"
            >
              <option value="0">Standard</option>
              <option value="1">MVP</option>
              <option value="2">Prototype</option>
            </Form.Control>
          </div>
        </div>
        {params.type === '0' &&
          <div className="submenu__item">
            <div className="submenu__item__text">
              <Form.Text>
                Number of tanks<br/>
                <span className="submenu__item__small-text">Between 0 and 12</span>
              </Form.Text>
            </div>
            <div className="submenu__item__input">
              <Form.Control
                plaintext
                type="number"
                min={0}
                max={12}
                value={params.numberOfTanks}
                name="numberOfTanks"
                onChange={this.onChange.bind(this)}
              />
            </div>
          </div>
        }
      </React.Fragment>
    );
  }
}

StorageSubmenu.propTypes = {
  params: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default StorageSubmenu;
