import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import RxPlayer from 'rx-player';

import './styles.sass';
import {plantService} from '../../../store/services/plant';
import {checkVideoAutoPlay} from '../../../helpers/video';
import Video from '../Video';
import { getFlowModeById } from 'helpers/store';
import Schema from '../Schema';
import {plantTesActions} from '../../../store/actions/plantTes';


class TesLiveView extends React.Component {

  player = null;
  state = {
    showManualPlay: false,
    videoState: null,
    schemaUrl: null
  };

  componentDidMount() {
    const {streams} = this.props;

    if (streams && streams.length > 0) {
      console.log('componentDidMount: delayInitializeVideo');
      this.delayInitializeVideo();
    }
  }

  componentDidUpdate(prevProps) {
    const {liveVideo, selectedStream, streams, status, flowModes} = this.props;

    // Schema change handler
    // url for local testing = 'http://localhost:3000/images/local_schemas/0/0_0.svg';
    if (status.global) {
      const {status: prevStatus, flowModes: prevFlowModes} = prevProps;
      const {flow_mode: flowMode, is_maintenance: isMaintenance} = status.global;
      const flowModeObj = getFlowModeById(flowModes, isMaintenance, flowMode);
      if (flowModeObj) {
        if (prevStatus && prevStatus.global && prevFlowModes) {
          const prevFlowModeObj = getFlowModeById(prevFlowModes, prevStatus.global.is_maintenance,
            prevStatus.global.flow_mode);
          if (prevFlowModeObj && prevFlowModeObj.schemaUrl !== flowModeObj.schemaUrl) {
            const url = flowModeObj.schemaUrl;
            console.log('Looking for schema:', url);
            this.setState({schemaUrl: url});
          }
        } else {
          const url = flowModeObj.schemaUrl;
          console.log('Looking for schema:', url);
          this.setState({schemaUrl: url});
        }
      }
    }

    // Video change hanlder
    if ((prevProps && prevProps.liveVideo === false && liveVideo === true) ||
        (prevProps && prevProps.selectedStream !== selectedStream) && streams.length > 0) {
      console.log('ComponentDidUpdate: initializeVideo');
      this.initializeVideo();
    }
    if (prevProps && prevProps.streams !== streams && streams.length > 0) {
      console.log('ComponentDidUpdate: delayInitializeVideo');
      this.delayInitializeVideo();
    }
    if (prevProps && prevProps.liveVideo === true && liveVideo === false) {
      this.player = null;
    }
    if (prevProps && !prevProps.status?.global && status && status.global) {
      console.log('ComponentDidUpdate: delayInitializeVideo from status change');
      this.delayInitializeVideo();
    }
  }

  delayInitializeVideo() {
    checkVideoAutoPlay(
      () => this.initializeVideo(),
      () => this.setState({showManualPlay: true})
    );
  }

  manualPlay() {
    this.setState({showManualPlay: false});
    this.initializeVideo();
  }

  initializeVideo() {
    if (!this.player) {
      const videoElement = document.querySelector('video');
      console.log('Creating video', videoElement);
      if (!videoElement) {
        console.log('Cannot find video element', videoElement);
        return;
      }
      this.player = new RxPlayer({ videoElement });
      this.player.addEventListener('error', (error) => {
        console.log('Video player error', error);
      });
      this.player.addEventListener('playerStateChange', (state) => {
        console.log('Video player state change', state);
        this.setState({videoState: state});
      });
    }
    this.player.stop();
    this.loadVideo();
  }

  loadVideo() {
    console.log('[TesLiveView] Loading new video for tes');
    const {selectedStream, streams} = this.props;

    if (streams === []) {
      console.log('streams returned empty array');
      return;
    }

    const streamName = streams[selectedStream]?.title;
    if (!streamName) return;
    console.log('Getting url for stream:', streamName);
    this.setState({videoState: 'GETTING_URL'});
    plantService.getStreamUrl(streamName).then(response => {
      const streamUrl = response.data;
      this.setState({videoState: 'GETTING_URL_SUCCEEDED'});

      console.log('Call loadVideo', streamUrl);
      this.player.loadVideo({
        url: streamUrl,
        transport: 'dash',
        autoPlay: true,
      });

    }).catch(error => {
      alert('Error while getting stream url:' + error);
    });
  }

  selectTank(tank) { this.props.dispatch(plantTesActions.selectTank(tank)); }
  selectValve(valve) { this.props.dispatch(plantTesActions.selectValve(valve)); }

  render() {
    const {status, liveVideo, menuTesInfo, selectedTank, selectedValve} = this.props;
    const {showManualPlay, videoState, schemaUrl} = this.state;
    if (!status || !status.global) return <div />;

    return (
      <div className={'tes-page'}>
        {!liveVideo && schemaUrl &&
          <Schema
            url={schemaUrl}
            menuVisible={menuTesInfo}
            status={status}
            selectedTank={selectedTank}
            selectedValve={selectedValve}
            selectTank={this.selectTank.bind(this)}
            selectValve={this.selectValve.bind(this)}
          />
        }
        {liveVideo &&
          <Video
            showManualPlay={showManualPlay}
            manualPlayOnClick={this.manualPlay.bind(this)}
            state={videoState}
          />
        }
      </div>
    );
  }
}

TesLiveView.propTypes = {
  status: PropTypes.object,
  flowModes: PropTypes.object,
  dispatch: PropTypes.func,
  streams: PropTypes.array,
  liveVideo: PropTypes.bool,
  selectedStream: PropTypes.number,
  menuTesInfo: PropTypes.bool,
  selectedTank: PropTypes.object,
  selectedValve: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    status: state.plant.tes.status,
    flowModes: state.plant.tes.modes,
    streams: state.plant.streams,
    liveVideo: state.menu.liveVideo,
    selectedStream: state.plant.selectedStream,
    menuTesInfo: state.menu.menuTesInfo,
    selectedTank: state.plant.selectedTank,
    selectedValve: state.plant.selectedValve
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TesLiveView));
