import {plantConstants} from '../constants/plant';
import {plantService} from '../services/plant';
import {plantStatusTypes} from '../constants/plant';
import {menuActions} from './menu';
import {store} from '../../store';

export const plantActions = {
  getList,
  select,
  getStatus,
  getStreams,
  getStreamUrl,
  nextStream,
  updateOnline,
  updateStatus
};

// TECHDEBT: helios and tes plants should use same statuses

function getList() {
  return (dispatch) => {
    dispatch({type: plantConstants.GETTING_LIST});
    return plantService.getList().then(response => {
      const list = response.data.plants;
      dispatch({type: plantConstants.GET_LIST_SUCCEEDED, payload: list});
    }).catch(error => {
      dispatch({type: plantConstants.GET_LIST_ERROR, payload: error});
      alert(`Error while getting plants list: ${error}`);
    });
  };
}

function getStreams() {
  return (dispatch) => {
    plantService.getStreams().then(response => {
      const streams = response.data.streams;
      dispatch({type: plantConstants.GET_STREAMS_SUCCEEDED, payload: streams});
    }).catch(error => {
      alert(`Error while getting streams list: ${error}`);
    });
  };
}

function select(plant) {
  return (dispatch) => {
    dispatch({type: plantConstants.SELECT, payload: plant});
  };
}

function getStatus() {
  return (dispatch) => {
    plantService.getStatus().then(response => {
      const plantStatus = response.data;
      if (!plantStatus) return;
      dispatch({
        type: plantConstants.GET_STATUS_SUCCEEDED, payload: plantStatus
      });
      _handleStatus(dispatch, plantStatus);
    });
  };
}

function getStreamUrl(streamName) {
  return (dispatch) => {
    return plantService.getStreamUrl(streamName).then(response => {
      const streamUrl = response.data.body;
      dispatch({
        type: plantConstants.GET_STREAM_URL_SUCCEEDED, payload: streamUrl
      });
    });
  };
}

function nextStream() {
  return {type: plantConstants.NEXT_STREAM};
}

function updateOnline(isOnline) {
  return (dispatch) => {
    const prevPlantStatus = store.getState().plant.status;
    const prevStatus = prevPlantStatus.status;
    if (prevPlantStatus.online !== isOnline) {
      dispatch({type: plantConstants.UPDATE_ONLINE, payload: isOnline});
    }
    if ((!prevPlantStatus.online || prevPlantStatus.online === true) && isOnline === false) {
      dispatch(menuActions.setHaltEmergency());
    }
    if ((prevPlantStatus.online === undefined || prevPlantStatus.online === false) && isOnline === true &&
        prevStatus === plantStatusTypes.OPERATIONAL) {
      dispatch(menuActions.setMenuInfoPlant());
    }
  };
}

function updateStatus(partialStatus) {
  return (dispatch) => {
    dispatch({type: plantConstants.UPDATE_STATUS, payload: partialStatus});
    _handleStatus(dispatch, partialStatus);
  };
}

function _handleStatus(dispatch, plantStatus) {
  if (plantStatus.status === undefined) return;
  const prevPlant = store.getState().plant;
  const prevPlantStatus = prevPlant.status;
  const prevStatus = prevPlantStatus.status;
  if (plantStatus.status === plantStatusTypes.HALTED || plantStatus.status === plantStatusTypes.MANUALLY_HALTED) {
    dispatch(menuActions.setHaltEmergency());
    return;
  }
  if (((prevStatus === plantStatusTypes.HALTED || prevStatus === plantStatusTypes.MANUALLY_HALTED) &&
    plantStatus.status === plantStatusTypes.OPERATIONAL)) {
    dispatch(menuActions.setMenuInfoPlant());
  }
}
