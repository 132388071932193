import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import clsx from 'clsx';

import './styles.sass';
import cspIcon from '../../../images/unity-menu/csp.svg';
import pvIcon from '../../../images/unity-menu/pv.svg';
import storageIcon from '../../../images/unity-menu/storage.svg';
import areaIcon from '../../../images/unity-menu/area.svg';
import topViewIcon from '../../../images/unity-menu/top_view.svg';
import angleIcon from '../../../images/unity-menu/angle.svg';
import {UnityProjectDesignCommunication} from '../../../helpers/UnityProjectDesignCommunication';
import NumberFormat from 'react-number-format';
import {calculationsHelper} from '../../../helpers/calculations';
import {calculationService} from '../../../store/services/calculations';
import SVG from 'react-inlinesvg';
import StorageSubmenu from './StorageSubmenu';
import SelectedObject from '../../../components/design/SelectedObject';
import PvSubmenu from './PvSubmenu';
import AreaSubmenu from './AreaSubmenu';
import {projectsActions} from '../../../store/actions/projects';
import {FormCheck} from 'react-bootstrap';

class UnityMenu extends React.Component {
  state = {
    angleView: true,
    editMode: false,
    secondCamera: false,
    saving: false,
    recalculate: false,
    selectedToolIndex: null,
    storageParams: {
      type: '0', // 0 => standard, 1 => mvp, 2 => prototype
      numberOfTanks: 5
    },
    pvParams: {
      rows: 1,
      cols: 1
    },
    selectedObject: null,
    areaCreated: false,
    areaPencilMode: false,
    snappingEnabled: true,
    compassEnabled: true,
  };

  toolsMenu = [{
    name: 'CSP',
    icon: cspIcon,
    callback: () => this.props.unityCommunication.drawCsp()
  }, {
    name: 'PV',
    icon: pvIcon,
    callback: () => this.props.unityCommunication.drawPv(this.state.pvParams),
    submenu: PvSubmenu,
    onChange: (name, value) => this.setPvParams(1, name, value),
    stateAttr: 'pvParams',
    unityCommunication: this.props.unityCommunication,
    areaCreated: this.state.areaCreated
  }, {
    name: 'Storage',
    icon: storageIcon,
    callback: () => this.props.unityCommunication.drawStorage(this.state.storageParams),
    submenu: StorageSubmenu,
    onChange: (name, value) => this.setStorageParams(2, name, value),
    stateAttr: 'storageParams'
  }, {
    name: 'Area',
    icon: areaIcon,
    submenu: AreaSubmenu,
    pencilMode: this.state.pencilMode,
    onChange: (enable) => this.togglePencilMode(enable),
    callback: () => this.props.unityCommunication.drawArea()
  }];

  constructor(props) {
    super(props);
    props.unityCommunication.addDeselectListener(() => this.setState({selectedToolIndex: null}));
    props.unityCommunication.addSelectObjectListener((data) =>
      this.setState({selectedObject: JSON.parse(data)})
    );
    props.unityCommunication.addDeselectObjectListener((guid) => {
      const {selectedObject} = this.state;
      if (selectedObject && selectedObject.guid === guid) {
        this.setState({selectedObject: null});
      }
    });
    props.unityCommunication.addObjectRotationListener((data) => {
      try {
        const {selectedObject} = this.state;
        const parsedData = JSON.parse(data);
        if (selectedObject && selectedObject.guid === parsedData.guid) {
          this.setState({
            selectedObject: {
              ...selectedObject,
              rotation: parsedData.rotation
            },
          });
        }
      } catch(e) {
        console.log('ObjectRotationListener error', e);
      }
    });
    props.unityCommunication.addAreaCreatedListener(() =>
    {
      this.setState({areaCreated: true});
    });
    props.unityCommunication.addAlertListener((message) => { alert(message); });
  }

  switchView() {
    const {unityCommunication} = this.props;
    const {angleView} = this.state;
    unityCommunication.toggleView();
    this.setState({angleView: !angleView});
  }

  switchEditMode() {
    const {unityCommunication} = this.props;
    const {editMode} = this.state;
    const newEditModeValue = !editMode;
    let newState = {editMode: newEditModeValue};
    unityCommunication.setEditMode(newEditModeValue);
    if (newEditModeValue) {
      newState['selectedToolIndex'] = null;
    }
    this.setState(newState);
  }

  toggleSnappingEnabled() {
    const {snappingEnabled} = this.state;
    const {unityCommunication} = this.props;
    unityCommunication.setSnappingEnabled(!snappingEnabled);

    this.setState(prevState => ({snappingEnabled: !prevState.snappingEnabled}));
  }

  toggleCompassEnabled() {
    const {compassEnabled} = this.state;
    const {unityCommunication} = this.props;
    unityCommunication.setCompassEnabled(!compassEnabled);

    this.setState(prevState => ({compassEnabled: !prevState.compassEnabled}));
  }

  switchCamera() {
    const {unityCommunication} = this.props;
    const {secondCamera} = this.state;
    unityCommunication.toggleCamera();
    this.setState({secondCamera: !secondCamera});
  }

  onSaveProjectClick() {
    const {unityCommunication} = this.props;
    const {saving} = this.state;
    if (saving) return;
    this.setState({saving: true});
    unityCommunication.getTesCapacity(this.updateTesCapacity.bind(this));
  }

  getSiteData(unityData) {
    const parsedUnityData = JSON.parse(unityData);
    const towers = parsedUnityData.towers;
    let towerCount = 0;
    let heliostatCount = 0;
    if (towers) {
      towers.forEach(tower => {
        towerCount += 1;
        heliostatCount += tower.heliostats.length;
      });
      console.log('Update towers and heliostats', towerCount, heliostatCount);
    }
    return {
      towers: towerCount,
      heliostats: heliostatCount
    };
  }

  updateTesCapacity(capacity) {
    const {unityCommunication} = this.props;
    const capacityNumber = parseInt(capacity.split(' ')[0]);
    console.log('Tes capacity', capacity, capacityNumber);
    unityCommunication.getProjectData((data) => this.saveProject(capacityNumber, data));
  }

  saveProject(storageSize, data) {
    console.log('Project JSON', data);
    const {dispatch} = this.props;
    const canvas = document.getElementById('#canvas');
    const canvasAsBase64 = canvas.toDataURL('image/jpeg', 0.2);
    dispatch(projectsActions.updateProject({
      settings: {image: canvasAsBase64},
      site: this.getSiteData(data),
      projectEnergyProperty: {storageSize},
      unityData: {data}
    })).then(() => {
      dispatch(projectsActions.saveProject()).then(() => {
        this.setState({saving: false});
      }).catch(error => {
        alert('Cannot save project: ' + error);
        this.setState({saving: false});
      });
      this.setState({recalculate: true});
    });
  }

  setTool(index, callback) {
    callback();
    this.setState({ selectedToolIndex: index });
  }

  setStorageParams(index, name, value) { this.setToolParams('storageParams', index, name, value); }
  setPvParams(index, name, value) { this.setToolParams('pvParams', index, name, value); }
  togglePencilMode() {
    const {unityCommunication} = this.props;
    unityCommunication.setPencilMode(!this.state.pencilMode);

    this.setState(prevState => ({pencilMode: !prevState.pencilMode}));
  }

  setToolParams(stateName, index, name, value) {
    this.setState({
      [stateName]: {
        ...this.state[stateName],
        [name]: value
      }
    }, () => {
      if (value !== '') this.toolsMenu[index].callback();
    });
  }

  onRotationChange(event) {
    try {
      const rotation = parseInt(event.target.value);
      const {unityCommunication} = this.props;
      unityCommunication.setRotation(rotation);
    } catch(e) {
      console.log('onRotationChange: Invalid value', e);
    }
  }

  deleteObject(selectedObject) {
    const {unityCommunication} = this.props;
    const confirmed = window.confirm('Are you sure you want to delete selected object?');
    if (confirmed) unityCommunication.deleteObject(selectedObject.guid);
  }

  deleteAreaCorner() {
    const {unityCommunication} = this.props;
    const confirmed = window.confirm('Are you sure you want to delete selected object?');
    if (confirmed) unityCommunication.deleteObject('');
  }

  componentDidUpdate() {
    const {site, settings, dispatch} = this.props;
    const {recalculate} = this.state;
    if (recalculate) this.setState({recalculate: false});

    if (site.annualProduction === undefined || recalculate) {
      let dataForRequest = calculationsHelper.getAnnualProductionRequestPayload(settings, site);
      calculationService.calculateAnnualProduction(dataForRequest).then(response => {
        let {estimatedAnnualProduction} = response.data;
        dispatch(projectsActions.updateProjectSite({annualProduction: estimatedAnnualProduction}));
      });
    }
    if (this.props.site.peakCapacity === undefined || recalculate) {
      let dataForRequest = calculationsHelper.getPeakCapacityRequestPayload(site);
      const peakCapacity = calculationService.calculatePeakCapacity(dataForRequest);
      dispatch(projectsActions.updateProjectSite({peakCapacity}));
    }
  }

  render() {
    const {site, projectEnergyProperty, settings} = this.props;
    const {angleView, saving, selectedToolIndex, editMode, secondCamera, selectedObject} = this.state;
    const selectedTool = selectedToolIndex ? this.toolsMenu[selectedToolIndex]:null;

    return (
      <div className="unity-menu">
        <div className="unity-menu__top">
          <div className="unity-menu__info">
            <div className="unity-menu__info__text-small">Project details</div>
            <div className="unity-menu__info__text-big">{settings.name}</div>
            <br/>
            <div>
              <div className="unity-menu__info__col">
                <div className="unity-menu__info__text-big">
                  <NumberFormat value={site.peakCapacity ? site.peakCapacity:'-'}
                    displayType={'text'} thousandSeparator={' '}/> MWp</div>
                <div className="unity-menu__info__text-small">Peak capacity</div>
              </div>
              <div className="unity-menu__info__col">
                <div className="unity-menu__info__text-big">
                  <NumberFormat value={projectEnergyProperty?.storageSize}
                    displayType={'text'}
                    thousandSeparator={' '}/> MW</div>
                <div className="unity-menu__info__text-small">Energy storage</div>
              </div>
              <div className="clearfix">
                <div className="unity-menu__info__text-big">
                  <NumberFormat value={site.annualProduction ? site.annualProduction.toFixed(2):'-'}
                    displayType={'text'}
                    thousandSeparator={' '}/> MWh</div>
                <div className="unity-menu__info__text-small">Annual output</div>
              </div>
            </div>
          </div>
          <div className="controls">
            <p className="section-title">Place objects:</p>
            {this.toolsMenu.map((tool, index) => {
              return (
                <button
                  className={clsx('controls__item', {'controls__item--active': index === selectedToolIndex})}
                  onClick={() => this.setTool(index, tool.callback)} key={`tool-icon-${index}`} disabled={editMode}
                >
                  <div className="controls__item__icon">
                    <SVG src={tool.icon} />
                  </div>
                  <div className="controls__item__text">{tool.name}</div>
                </button>
              );
            })}
          </div>
          {selectedTool?.submenu &&
            <div className="submenu">
              <selectedTool.submenu
                onChange={selectedTool.onChange.bind(this)}
                params={this.state[selectedTool.stateAttr]}
                unityCommunication={this.props.unityCommunication}
                areaCreated={this.state.areaCreated}
                pencilMode={this.state.pencilMode}
              />
            </div>
          }
          {selectedObject &&
            <SelectedObject
              selectedObject={selectedObject}
              onRotationChange={this.onRotationChange.bind(this)}
              deleteObject={this.deleteObject.bind(this)}
              deleteAreaCorner={this.deleteAreaCorner.bind(this)}
            />
          }
        </div>
        <div className="unity-menu__bottom">
          <div className="controls controls--with-padding">
            <p className="section-title">Options:</p>
            <div className="controls__row">
              <div className="controls__button" onClick={this.switchCamera.bind(this)}>
                <div className="controls__button__text">
                  {secondCamera ? 'Camera 1':'Camera 2'}
                </div>
              </div>
              {!secondCamera &&
                <React.Fragment>
                  <div className="controls__button" onClick={this.switchView.bind(this)}>
                    <div className="controls__button__icon">
                      <img src={angleView ? topViewIcon : angleIcon} alt={angleView ? 'Top view' : 'Angle view'}/>
                    </div>
                    <div className="controls__button__text">
                      {angleView ? 'Top view' : 'Angle view'}
                    </div>
                  </div>
                </React.Fragment>
              }
            </div>
            <div className="controls__row">
              <div className="controls__button" onClick={this.switchEditMode.bind(this)}>
                <div className="controls__button__text">
                  {editMode ? 'Add mode':'Edit mode'}
                </div>
              </div>
            </div>
            <div className="controls__row">
              <React.Fragment>
                <div className="controls__button">
                  <div className="controls__button__text">
                    <FormCheck>
                      <FormCheck.Input className="no-top-margin" onChange={this.toggleCompassEnabled.bind(this)} checked={this.state.compassEnabled} />
                      <FormCheck.Label>Compass</FormCheck.Label>
                    </FormCheck>
                  </div>
                </div>
              </React.Fragment>
              {editMode &&
                <React.Fragment>
                  <div className="controls__button">
                    <div className="controls__button__text">
                      <FormCheck>
                        <FormCheck.Input className="no-top-margin" onChange={this.toggleSnappingEnabled.bind(this)} checked={this.state.snappingEnabled} />
                        <FormCheck.Label>Snapping</FormCheck.Label>
                      </FormCheck>
                    </div>
                  </div>
                </React.Fragment>
              }
            </div>
          </div>
          <div className="big-button" onClick={this.onSaveProjectClick.bind(this)}>
            {saving ? 'Saving project...':'Save project'}
          </div>
        </div>
      </div>
    );
  }
}

UnityMenu.propTypes = {
  dispatch: PropTypes.func,
  unityCommunication: PropTypes.instanceOf(UnityProjectDesignCommunication),
  unityData: PropTypes.object,
  site: PropTypes.object,
  projectEnergyProperty: PropTypes.object,
  settings: PropTypes.object
};

const mapStateToProps = (state) => {
  const project = state.projects.project;
  return {
    unityData: project?.unityData,
    site: project?.site,
    projectEnergyProperty: project?.projectEnergyProperty,
    settings: project?.settings
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnityMenu);
