export const socketConstants = {
  CONNECT: 'SOCKET_CONNECT',
  CONNECTION_ERROR: 'SOCKET_CONNECTION_ERROR',
  CONNECTED: 'SOCKET_CONNECTED',
  JOIN: 'SOCKET_JOIN',
  JOINED: 'SOCKET_JOINED',
  DISCONNECT: 'SOCKET_DISCONNECT',
  DISCONNECTED: 'SOCKET_DISCONNECTED'
};

export const socketChannels = {
  HEARTBEAT: 'heartbeat',
  EVENTS: 'events',
  TES_STATUS: 'tes_status',
  STATUS: 'status',
  HELIOSTAT: 'heliostat'
};

export const socketEventActions = {
  NEW: 'NEW_EVENT',
  RESOLVE: 'RESOLVE_EVENT',
  DISMISS_ALL: 'DISMISS_ALL'
};
