import React from 'react';
import {filesService} from '../../store/services/files';
import CustomSpinner from '../../components/operate/CustomSpinner';
import {downloadFromUrl} from '../../helpers/utils';

class AvailableFiles extends React.Component {
  state = {
    loading: false,
    files: null
  }

  componentDidMount() {
    this.setState({loading: true});
    filesService.getCsvImports().then(response => {
      this.setState({files: response.data, loading: false});
    }).catch(e => {
      this.setState({loading: false});
      window.alert('Cannot load files: ' + e);
      console.log('Cannot load files', e);
    });
  }

  downloadFile(event, fileName) {
    event.preventDefault();
    filesService.getCsvImport(fileName).then(response => {
      console.log('File url', response.data);
      downloadFromUrl(response.data);
    }).catch(e => {
      window.alert('Cannot download file: ' + e);
      console.log('Cannot download file', e);
    });
  }

  renderFiles() {
    const {files} = this.state;
    return files.map((file, index) => (
      <div key={'download-file-' + index}>
        <a href="#" onClick={(e) => this.downloadFile(e, file)}>{file}</a>
      </div>
    ));
  }

  render() {
    const {files, loading} = this.state;
    return (
      <div>
        <h2>Available files</h2>
        {loading && <CustomSpinner size={24} color={'#212529'} />}
        {!loading && !files && <p>No files available</p>}
        {!loading && files && this.renderFiles()}
      </div>
    );
  }
}

export default AvailableFiles;
