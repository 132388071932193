import React, {Component} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './styles.sass';
import InnerMenu from './InnerMenu';
import MenuPlantInfo from './MenuInfoPlant';
import MenuInfoHeliostat from './MenuInfoHeliostat';
import MenuInfoMaintenance from './MenuInfoMaintenance';
import MenuInfoProdaction from './MenuInfoProduction';
import HasModuleActive from '../../HasModuleActive';
import {plantModules} from '../../../store/constants/plant';


export class MenuInfoBase extends Component {

  render() {
    const {innerMenu, menuInfoPlant, menuInfoHeliostat, menuInfoMaintenance, menuInfoProduction} = this.props;
    return (
      <div className={'menu-info-wrapper'}>
        {innerMenu ? <InnerMenu /> : ''}
        <div className="menu-info">
          {menuInfoPlant && <MenuPlantInfo />}
          <HasModuleActive module={plantModules.HELIOSTATS}>
            {menuInfoHeliostat && <MenuInfoHeliostat />}
            {menuInfoMaintenance && <MenuInfoMaintenance />}
            {menuInfoProduction && <MenuInfoProdaction />}
          </HasModuleActive>
        </div>
      </div>
    );
  }
}

MenuInfoBase.propTypes = {
  innerMenu: PropTypes.bool,
  menuInfoPlant: PropTypes.bool,
  menuInfoHeliostat: PropTypes.bool,
  menuInfoMaintenance: PropTypes.bool,
  menuInfoProduction: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    innerMenu: state.menu.innerMenu,
    menuInfoPlant: state.menu.menuInfoPlant,
    menuInfoHeliostat: state.menu.menuInfoHeliostat,
    menuInfoMaintenance: state.menu.menuInfoMaintenance,
    menuInfoProduction: state.menu.menuInfoProduction,
    heliostats: state.heliostat.heliostats
  };
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuInfoBase);
